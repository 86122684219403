import "./Login.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import logo from "./Icon.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { usePost } from "../../db/dbHooks.js";
import SubmitBT from "../../components/buttons/SubmitBT.js";

//TODO - change get element by id to one state
export default function Login({ setUser }) {
  const [cookies, setCookie] = useCookies(["token"]);
  const [errorMassage, setErrorMessage] = useState("");
  const { postResponse, postLoading, postError, postData } = usePost(
    "auth/login",
    ""
  );

  useEffect(() => {
    postError && setErrorMessage("Wrong username or password");
    if (postResponse) {
      const stayLoggedin = document.getElementById("rememberMe").checked;
      setErrorMessage("");
      setUser({
        userID: postResponse.userID,
        username: postResponse.username,
        userType: postResponse.userType,
        token: postResponse.token,
        expDate: postResponse.expDate,
      });
      if (stayLoggedin) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 7); // Set expiration to 7 days from now
        setCookie("token", postResponse.token, {
          httpOnly: false,
          expires: expiryDate,
        });
        localStorage.setItem(
          "NF_DB_USER",
          JSON.stringify({
            userID: postResponse.userID,
            username: postResponse.username,
            userType: postResponse.userType,
            expDate: postResponse.expDate,
          })
        );
      } else {
      }
    }
  }, [postError, postResponse]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const username = document.getElementById("userName").value;
    const password = document.getElementById("pass").value;

    //check the length of pass and username
    if (password.length > 7 && username.length > 5) {
      postData({
        username,
        password,
        stayLoggedin: document.getElementById("rememberMe").checked,
      });
    } else {
      if (password.length === 0 || username.length === 0) {
        setErrorMessage("Username or password can't be empty");
      } else {
        if (password.length < 8)
          setErrorMessage("Password should be more than 7 letter");
        else setErrorMessage("Username should be more than 5 letter");
      }
    }
  };

  return (
    <div className="login">
      <img className="logo" src={logo} />
      <div className="form">
        <FloatingLabel controlId="userName" label="User Name" className="mb-3">
          <Form.Control
            type="text"
            placeholder="John123"
            onChange={() => setErrorMessage("")}
          />
        </FloatingLabel>
        <FloatingLabel controlId="pass" label="Password">
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={() => setErrorMessage("")}
          />
        </FloatingLabel>
        <Row>
          <Col>
            <Form.Check // prettier-ignore
              type="checkbox"
              id="rememberMe"
              label="Remember Me"
              style={{ textAlign: "left" }}
            />
          </Col>
        </Row>
        <p
          className="text-center"
          style={{
            color: "#ba0202",
            display: errorMassage !== "" ? "" : "none",
            margin: "auto",
          }}
        >
          {errorMassage}{" "}
        </p>

        <SubmitBT
          BTname="Sign in"
          onClick={handleSubmit}
          loading={postLoading}
        />
      </div>
    </div>
  );
}
