import "./buttons.css";
import { Link } from "react-router-dom";

export default function FolatingBT({ link }) {
  return (
    <Link className="floating-bg" to={link}>
      +
    </Link>
  );
}
