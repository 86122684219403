import ListGroup from "react-bootstrap/ListGroup";
import { useState, useMemo } from "react";
import "./List.css";
import Filter from "../filter/Filter";

export default function CustomerList({
  customerData,
  setCustomerToDelete,
  setCustomerToEdit,
  disableListMenu = false,
}) {
  const [search, setSearch] = useState("");
  const [clickedItem, setClickedItem] = useState(-1);

  // Memoize filtered data to improve performance
  const filteredCustomers = useMemo(() => {
    const searchRegex = new RegExp(search, "i");
    return customerData?.filter(
      (customer) =>
        search === "" ||
        searchRegex.test(customer.customerName) ||
        searchRegex.test(customer.routeName)
    );
  }, [search, customerData]);

  return (
    <div className="list-container">
      {customerData?.length > 0 ? (
        <>
          <div className="sticky">
            <Filter setSearch={setSearch} />
            <ListGroup horizontal className="list-header">
              <ListGroup.Item className="w-75">Customer Name</ListGroup.Item>
              <ListGroup.Item className="item2-3 w-25">Route</ListGroup.Item>
            </ListGroup>
          </div>
          <div className="list-body">
            {filteredCustomers.length > 0 ? (
              filteredCustomers.map((customer, index) => (
                <div key={customer.customerID}>
                  <ListGroup
                    horizontal
                    onClick={() =>
                      disableListMenu
                        ? setCustomerToEdit(customer)
                        : setClickedItem(clickedItem === index ? -1 : index)
                    }
                  >
                    <ListGroup.Item className="item1 w-75">
                      {customer.customerName}
                    </ListGroup.Item>
                    <ListGroup.Item className="item2-3 w-25">
                      {customer.routeName}
                    </ListGroup.Item>
                  </ListGroup>
                  {clickedItem === index && !disableListMenu && (
                    <ListGroup>
                      <ListGroup.Item
                        className="modify-list-item"
                        onClick={() => setCustomerToEdit(customer)}
                      >
                        Modify {customer.customerName}
                      </ListGroup.Item>
                      <ListGroup.Item
                        className="delete-list-item"
                        onClick={() => setCustomerToDelete(customer)}
                      >
                        Delete {customer.customerName}
                      </ListGroup.Item>
                    </ListGroup>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center mt-4">No customers found</p>
            )}
          </div>
        </>
      ) : (
        <p className="text-center mt-4">No customers added</p>
      )}
    </div>
  );
}
