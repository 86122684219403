import { useState, useEffect, useCallback } from "react";
import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import Customer from "../../components/forms/Customer";
import CustomerList from "../../components/list/CustomerList";
import { useFetch, useDelete } from "../../db/dbHooks";
import { ConfirmationPopup } from "../../components/floating/ConfirmationPopup";
import Popup from "../../components/floating/Popup";
import "./Manage.css";
export default function ManageCustomers() {
  const [selectedTab, setSelectedTab] = useState(null);
  const [customerToEdit, setCustomerToEdit] = useState(null);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { data, loading, error, refetch } = useFetch("customers");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("customers");

  // Reset customerToEdit when the selectedTab changes
  useEffect(() => {
    setCustomerToEdit(null);
  }, [selectedTab]);

  // Show popup on delete response or error
  useEffect(() => {
    if (deleteResponse || deleteError) {
      setShowPopup(true);
    }
  }, [deleteResponse, deleteError]);

  // Trigger refetch after update and reset edit state
  const onUpdate = useCallback(() => {
    refetch();
    setCustomerToEdit(null);
  }, [refetch]);

  // Handle delete action
  const handleDelete = useCallback(
    (id) => {
      deleteData(id);
    },
    [deleteData]
  );

  // Handle popup dismissal and refetch on successful delete
  const handleShowPopup = useCallback(() => {
    setShowPopup(false);
    if (deleteResponse) refetch();
  }, [deleteResponse, refetch]);

  return (
    <div className="form-page">
      <PageTitle title="Manage Customers" />

      <FormTab
        tabs={["New Customer", "Edit Customer"]}
        selected={setSelectedTab}
      />

      {selectedTab === "New Customer" && <Customer triggerUpdate={onUpdate} />}

      {selectedTab === "Edit Customer" && !customerToEdit && (
        <div className="manage-list-container">
          <CustomerList
            customerData={data}
            setCustomerToEdit={setCustomerToEdit}
            setCustomerToDelete={setCustomerToDelete}
          />
        </div>
      )}

      {customerToEdit && (
        <Customer customerToEdit={customerToEdit} triggerUpdate={onUpdate} />
      )}

      {customerToDelete && (
        <ConfirmationPopup
          setTrigger={() => setCustomerToDelete(null)}
          setAction={(e) => e && handleDelete(customerToDelete.customerID)}
          message={`Are you sure you want to delete ${customerToDelete.customerName}?`}
        />
      )}

      {showPopup && (
        <Popup
          error={deleteError}
          response={deleteResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </div>
  );
}
