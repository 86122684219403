import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { usePost, useUpdate, useFetch } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";

import Popup from "../floating/Popup";

export default function VanForm({ vanToEdit = null, reFetch }) {
  const {
    data: drivers,
    loading: driversLoading,
    error: driversError,
  } = useFetch("users/drivers");

  const [showPopup, setShowPopup] = useState(false);
  const [van, setVan] = useState(
    vanToEdit
      ? vanToEdit
      : {
          vanName: "",
          vanReg: "",
        }
  );
  const { postResponse, postLoading, postError, postData } = usePost("vans");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("vans");

  useEffect(() => {
    if (postError || updateError) {
      setShowPopup(true);
    } else if (postResponse || updateResponse) {
      setShowPopup(true);
      resetState();
    } else if (!vanToEdit) {
      resetState();
    }
  }, [vanToEdit, postResponse, updateResponse, postError, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (van.vanName.length > 3) {
      if (vanToEdit) {
        updateData(van.vanID, van);
      } else {
        postData(van);
      }
    }
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    reFetch();
  };

  const resetState = () => {
    setVan({
      vanName: "",
    });
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Van Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter category name"
          id="categoryName"
          autoComplete="off"
          value={van.vanName}
          onChange={(e) => setVan({ ...van, vanName: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Van Reg</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter van reg"
          id="vanReg"
          autoComplete="off"
          value={van.vanReg}
          onChange={(e) => setVan({ ...van, vanReg: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Driver</Form.Label>
        <Form.Select
          id="driver"
          value={van.userID}
          onChange={(e) => setVan({ ...van, userID: e.target.value })}
        >
          <option>Choose Driver</option>
          {drivers
            ? drivers.map((driver) => (
                <option key={driver.userID} value={driver.userID}>
                  {driver.username}
                </option>
              ))
            : ""}
        </Form.Select>
      </Form.Group>
      <SubmitBT
        BTname={vanToEdit ? "Update Van" : "Add Van"}
        loading={postLoading || updateLoading}
        onClick={handleSubmit}
      />
      {showPopup && (
        <Popup
          error={postError?.message || updateError?.message}
          response={postResponse?.message || updateResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </Form>
  );
}
