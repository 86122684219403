import moment from "moment";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function euDate(date) {
  return moment(date).format("ddd DD/MMM/YYYY");
}

function ddmmyy(date) {
  return moment(date).format("DD/MM/YYYY");
}

function sqlDate(date = null) {
  if (date !== null) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return moment().format("YYYY-MM-DD");
  }
}

function nextDeliveryDates(routeDays) {
  let dates = [];
  routeDays &&
    routeDays.map((day) => {
      let d = new Date();
      d.setDate(d.getDate() + ((Number(day) - 1 + 7 - d.getDay()) % 7));
      dates.push(d);
    });
  dates.sort((a, b) => new Date(a) - new Date(b));
  return dates;
}

function storeInLocalDB(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}
function getFromLocalDB(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
}

export {
  sqlDate,
  euDate,
  nextDeliveryDates,
  ddmmyy,
  storeInLocalDB,
  getFromLocalDB,
};
