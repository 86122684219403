import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import LogoutBT from "../buttons/LogoutBT";
import { usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";

export default function Register({
  userToEdit = null,
  viewOnly = false,
  reFetch,
}) {
  const checkBoxes = [
    { ID: "Admin" },
    { ID: "Orders" },
    { ID: "Dispatch" },
    { ID: "Driver" },
  ];
  const [user, setUser] = useState(
    userToEdit
      ? {
          userID: userToEdit.userID,
          username: userToEdit.username,
          password: "",
          confirmPass: "",
          userType: userToEdit.userType,
        }
      : { username: "", password: "", confirmPass: "", userType: "" }
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("auth/register");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("auth/register");

  useEffect(() => {
    if (postError || updateError) {
      alert(postError || updateError);
    } else if (postResponse || updateResponse) {
      reFetch();
      console.log(postResponse || updateResponse);
      resetState();
    } else if (!userToEdit) {
      resetState();
    }
  }, [userToEdit, postResponse, updateResponse, postError, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (user.password.length > 7 && user.password === user.confirmPass) {
      if (user.username.length > 5 && user.userType !== "") {
        if (userToEdit) {
          updateData(user.userID, { user });
        } else {
          postData(user);
        }
      } else {
        // userType or userName Error
      }
    } else {
      if (user.password.length < 8) {
        // Password must be atleast 8
      } else {
        //password not matching
      }
    }
  };

  const resetState = () => {
    setUser({ username: "", password: "", confirmPass: "", userType: "" });
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <Form.Group as={Col} controlId="userName">
        <Form.Label>Username</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="E.g: John123"
          value={user.username}
          onChange={(e) => setUser({ ...user, username: e.target.value })}
          disabled={viewOnly}
        />
        <Form.Control.Feedback type="invalid">
          {user.username === ""
            ? "Please choose a username"
            : "username should be more than 4 character"}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="password" hidden={viewOnly}>
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        <Form.Control.Feedback type="invalid">
          Please choose a username.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="confirmPassword" hidden={viewOnly}>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Confirm Password"
          value={user.confirmPass}
          onChange={(e) => setUser({ ...user, confirmPass: e.target.value })}
        />
      </Form.Group>
      <Form.Label>Role:</Form.Label>
      <Row className="justify-content-around">
        {checkBoxes.map((box) => (
          <Form.Check
            key={box.ID}
            className="w-auto"
            type="checkbox"
            id={box.ID}
            label={box.ID}
            checked={user.userType === box.ID}
            onChange={(e) => setUser({ ...user, userType: e.target.id })}
            disabled={viewOnly}
          />
        ))}
      </Row>
      <SubmitBT
        BTname={userToEdit ? "Update User" : "Add User"}
        loading={postLoading || updateLoading}
        onClick={handleSubmit}
        hidden={viewOnly}
      />{" "}
      <div style={{ margin: "auto" }} hidden={!viewOnly}>
        <LogoutBT />
      </div>
    </Form>
  );
}
