import "./Loader.css";

export default function Loader({ color, style }) {
  return (
    <div className="loader" style={style}>
      <div style={{ background: color }}></div>
      <div style={{ background: color }}></div>
      <div style={{ background: color }}></div>
      <div style={{ background: color }}></div>
    </div>
  );
}
