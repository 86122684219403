import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect, useState, useRef } from "react";
import SubmitBT from "../buttons/SubmitBT";
import { usePost, useUpdate } from "../../db/dbHooks";
import Popup from "../floating/Popup";

export default function Product({ productToEdit = null, reFetch, category }) {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const priceRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [product, setProduct] = useState(
    productToEdit
      ? productToEdit
      : {
          productName: "",
          unit: "",
          price: 0,
          categoryID: "",
        }
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("products");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("products");

  useEffect(() => {
    if (postError || updateError) {
      setShowPopup(true);
    } else if (postResponse || updateResponse) {
      resetState();

      setShowPopup(true);
    } else if (!productToEdit) {
      resetState();
    }
  }, [productToEdit, postResponse, updateResponse, postError, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (productToEdit) {
        if (JSON.stringify(productToEdit) !== JSON.stringify(product)) {
          updateData(product.productID, product);
        } else {
          setErrorMessage("No Changes Were Made");
          setShowPopup(true);
        }
      } else {
        postData(product);
      }
    }
    setValidated(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setErrorMessage(null);
    !errorMessage && reFetch();
  };

  const resetState = () => {
    setValidated(false);
    setProduct({
      productName: "",
      unit: "",
      price: 0,
      categoryID: "",
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Product Name</Form.Label>
        <Form.Control
          id="productName"
          type="text"
          autoComplete="off"
          placeholder="Enter product name"
          value={product.productName}
          required
          onChange={(e) =>
            setProduct({ ...product, productName: e.target.value })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Unit</Form.Label>
        <Form.Control
          id="unit"
          placeholder="Optional"
          required
          value={product.unit}
          onChange={(e) => setProduct({ ...product, unit: e.target.value })}
        />
      </Form.Group>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>Category</Form.Label>
          <Form.Select
            id="categoryID"
            value={product.categoryID}
            required
            isInvalid={validated && product.categoryID === ""}
            onChange={(e) =>
              setProduct({ ...product, categoryID: e.target.value })
            }
          >
            <option value={""}>Choose Category</option>
            {category
              ? category.map((category) => (
                  <option key={category.categoryID} value={category.categoryID}>
                    {category.categoryName}
                  </option>
                ))
              : ""}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Price</Form.Label>
          <Form.Control
            id="price"
            required
            placeholder="Optional - Example: 11.4"
            type="number"
            value={Number(product.price)}
            ref={priceRef}
            onFocus={() => priceRef.current.select()}
            onChange={(e) =>
              setProduct({ ...product, price: Number(e.target.value) })
            }
          />
        </Form.Group>
      </Row>

      <SubmitBT
        BTname={productToEdit ? "Update Product" : "Add Product"}
        loading={postLoading || updateLoading}
      />
      {showPopup && (
        <Popup
          error={postError || updateError || errorMessage}
          response={postResponse?.message || updateResponse?.message}
          setTrigger={closePopup}
        />
      )}
    </Form>
  );
}
