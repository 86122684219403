import ListGroup from "react-bootstrap/ListGroup";
import "./OutstandingOrders.css";
import {
  TelephoneFill,
  ClipboardPlusFill,
  ClipboardXFill,
  PenFill,
  EyeFill,
} from "react-bootstrap-icons";
import { useFetch, usePost } from "../../db/dbHooks";
import { useState, useEffect, useMemo } from "react";
import { euDate, sqlDate } from "../../fun/fun";
import { useNavigate } from "react-router-dom";
import OrderView from "../createOder/OrderView";
import Loader from "../loader/Loader";
import { ConfirmationPopup } from "../floating/ConfirmationPopup";

const OutstandingDetailed = ({ route, setRoute }) => {
  const [showOrderView, setShowOrderView] = useState({
    trigger: false,
    orderID: null,
  });

  const { data, loading, error, refetch } = useFetch(
    `orders/routeDetails/${route.routeID}?date=${sqlDate(route.runDate)}`
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("orders/noOrder");
  useEffect(() => {
    postResponse && refetch();
  }, [postResponse]);
  const navigate = useNavigate();

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const markNoOrder = (customer) => {
    const order = {
      customerID: customer.customerID,
      routeID: route.routeID,
      orderDate: sqlDate(new Date()),
      deliveryDate: sqlDate(route.runDate),
      total: null,
      note: null,
      status: "No Order",
    };
    postData({ order });
  };
  const goToCreateOrder = (customer) => {
    navigate("/newOrder", {
      state: {
        ...customer,
        deliveryDate: route.runDate,
        routeName: data.routeData.routeName,
        routeID: data.routeData.routeID,
        days: data.routeData.days,
      },
    });
  };

  return (
    <div className="fixed-bg" onClick={() => setRoute(null)}>
      <div className="detailed-inner" onClick={preventBubbling}>
        <h4
          className="sticky"
          style={{ backgroundColor: "white", padding: "10px 0" }}
        >
          {" "}
          {route.routeName + " " + euDate(route.date)}
        </h4>
        {data ? (
          <div className="aaa mb-2">
            <p className="list-divider my-2">Orders To Get</p>
            <OrdersToGetList
              customers={data.routeDetails.filter(
                (item) => item.orderID === null
              )}
              createOrder={(e) => goToCreateOrder(e)}
              setNoOrder={(e) => markNoOrder(e)}
            />

            <p className="list-divider my-2">Available Orders</p>
            <AvailableOrdersList
              customers={data.routeDetails.filter(
                (item) => item.total !== null
              )}
              setOrderID={(e) =>
                setShowOrderView({ trigger: true, orderID: e })
              }
              setEditOrder={(e) => {
                goToCreateOrder(e);
              }}
            />
            <p className="list-divider my-2">No Orders</p>
            <AvailableOrdersList
              disableView={true}
              customers={data.routeDetails.filter(
                (item) => item.status === "No Order" && item.total === null
              )}
              setOrderID={(e) =>
                setShowOrderView({ trigger: true, orderID: e })
              }
              setEditOrder={(e) => {
                goToCreateOrder(e);
              }}
            />
          </div>
        ) : (
          loading && <Loader color={"Black"} />
        )}
      </div>
      {showOrderView.trigger && (
        <OrderView
          orderID={showOrderView.orderID}
          setTrigger={() => setShowOrderView({ trigger: false, orderID: null })}
          clearBg={true}
        />
      )}
    </div>
  );
};

export default OutstandingDetailed;

const OrdersToGetList = ({ customers = [], createOrder, setNoOrder }) => {
  // Memoize customers without orders to avoid recalculating on every render
  const customersWithoutOrders = useMemo(() => {
    return customers.filter((customer) => !customer.orderID);
  }, [customers]);

  // Early return if no customers are provided
  if (!customers.length) {
    return <p className="m-1">No orders to get</p>;
  }

  return (
    <>
      {customersWithoutOrders.length > 0 ? (
        customersWithoutOrders.map(
          ({ customerID, customerName, customerNumber }) => (
            <ListGroup horizontal key={customerID}>
              <ListGroup.Item className="item-name">
                {customerName}
              </ListGroup.Item>
              <ListGroup.Item className="item-qty">
                <ClipboardXFill
                  color="Red"
                  onClick={() => setNoOrder({ customerID, customerName })}
                />
              </ListGroup.Item>
              <ListGroup.Item className="item-qty">
                <ClipboardPlusFill
                  onClick={() => createOrder({ customerID, customerName })}
                />
              </ListGroup.Item>
              <ListGroup.Item className="item-qty">
                <a href={`tel:${customerNumber}`}>
                  <TelephoneFill color="Green" />
                </a>
              </ListGroup.Item>
            </ListGroup>
          )
        )
      ) : (
        <p className="m-1">No customers without orders</p>
      )}
    </>
  );
};

const AvailableOrdersList = ({
  customers = null,
  setOrderID,
  setEditOrder,
  disableView = false,
}) => {
  return (
    <>
      {customers.length > 0 ? (
        customers.map((customer) => (
          <ListGroup horizontal key={customer.customerID}>
            <ListGroup.Item className="item-name">
              {customer.customerName}
            </ListGroup.Item>
            <ListGroup.Item
              className="item-qty"
              onClick={() => setEditOrder(customer)}
            >
              <PenFill />
            </ListGroup.Item>
            <ListGroup.Item
              disabled={disableView}
              className="item-qty"
              onClick={() => setOrderID(customer.orderID)}
            >
              <EyeFill />
            </ListGroup.Item>
            <ListGroup.Item className="item-qty">
              <a href="tel:12345678">
                <TelephoneFill color="Green" />
              </a>
            </ListGroup.Item>
          </ListGroup>
        ))
      ) : (
        <p className="m-1"> No Orders</p>
      )}
    </>
  );
};
