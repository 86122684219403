import "./Floating.css";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import { useEffect } from "react";

export const ConfirmationPopup = ({ setTrigger, setAction, message = "" }) => {
  const handleClick = (e) => {
    setAction(e);
    setTrigger(false);
  };
  return (
    <div className="fixed-bg" onClick={() => ""}>
      <div className="ConfirmationPopup-inner">
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "15px" }}
        >
          <XCircle color="Red" fontSize={40} style={{ marginRight: "5px" }} />
          <p style={{ fontWeight: 600, marginLeft: "10px", marginBottom: 0 }}>
            {message}
          </p>
        </div>
        <div className="d-flex justify-content-evenly">
          <button
            style={{ backgroundColor: "red" }}
            onClick={() => handleClick(true)}
          >
            Delete
          </button>
          <button onClick={() => handleClick(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
