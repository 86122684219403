import ListGroup from "react-bootstrap/ListGroup";
import DeleteBT from "../buttons/DeleteBT";
import { useState, useEffect } from "react";
import "./List.css";
import Filter from "../filter/Filter";
import { useDelete } from "../../db/dbHooks";
import { ListItemMenu } from "../list/List";
import { ConfirmationPopup } from "../floating/ConfirmationPopup";

function ProductList({
  productData,
  updateData,
  setProductToDelete,
  setProductToEdit,
}) {
  const [search, setSearch] = useState("");
  const [clickedItem, setClickedItem] = useState(-1);

  return (
    <div className="list-container">
      {productData?.length > 0 ? (
        <>
          <div className="sticky">
            <Filter setSearch={setSearch} />
            <ListGroup horizontal className="list-header">
              <ListGroup.Item className="item1">Product Name</ListGroup.Item>
              <ListGroup.Item className="item2-3 w-20">Unit</ListGroup.Item>
              <ListGroup.Item className="item2-3 w-20">Price</ListGroup.Item>
            </ListGroup>
          </div>
          <div className="list-body">
            {productData &&
              productData.map(
                (product, index) =>
                  (product.productName.search(new RegExp(search, "i")) !== -1 ||
                    product.categoryName.search(new RegExp(search, "i")) !==
                      -1 ||
                    search === "") && (
                    <div key={product.productID}>
                      <ListGroup
                        horizontal
                        onClick={() =>
                          setClickedItem(clickedItem === index ? -1 : index)
                        }
                      >
                        <ListGroup.Item className="item1">
                          {product.categoryName + " " + product.productName}
                        </ListGroup.Item>
                        <ListGroup.Item className="item2-3 w-20">
                          {product.unit}
                        </ListGroup.Item>
                        <ListGroup.Item className="item2-3 w-20">
                          {Number(product.price)}
                        </ListGroup.Item>
                      </ListGroup>
                      {clickedItem === index && (
                        <div>
                          <ListGroup>
                            <ListGroup.Item
                              className="modify-list-item"
                              onClick={() => setProductToEdit(product)}
                            >
                              {"Modify " + product.productName}
                            </ListGroup.Item>
                            <ListGroup.Item
                              className="delete-list-item"
                              onClick={() => setProductToDelete(product)}
                            >
                              {"Delete " + product.productName}
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      )}
                    </div>
                  )
              )}
          </div>
        </>
      ) : (
        <p className="text-center mt-4">No products added</p>
      )}
    </div>
  );
}

export default ProductList;
