import ListGroup from "react-bootstrap/ListGroup";
import { useFetch } from "../../db/dbHooks";
import Loader from "../loader/Loader";

const DispatchQuantityList = ({ activeItem, setclickedItem }) => {
  const { data, loading, error, refetch } = useFetch(
    "orderDetails/totalQuantity"
  );
  return (
    <div className="text-center">
      {data
        ? data.map((item, index) => (
            <ListGroup
              horizontal
              key={index}
              onClick={() => {
                setclickedItem(item);
              }}
            >
              <ListGroup.Item
                active={activeItem?.product?.productID === item.productID}
                style={{
                  width: "60%",
                  textAlign: "left",
                  alignContent: "center",
                }}
              >
                {item.categoryName + " " + item.productName}
              </ListGroup.Item>
              <ListGroup.Item
                active={activeItem?.product?.productID === item.productID}
                style={{ width: "40%", alignContent: "center" }}
              >
                {Number(item.totalQuantity) + (" " + item.unit)}
              </ListGroup.Item>
            </ListGroup>
          ))
        : loading && <Loader color={"black"} />}
    </div>
  );
};

export default DispatchQuantityList;
