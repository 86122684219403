import CreateOrder from "../../components/createOder/CreateOrder";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import { useFetch } from "../../db/dbHooks";
import { useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Filter from "../../components/filter/Filter";
import Loader from "../../components/loader/Loader";

export default function NewOrder() {
  const location = useLocation();
  const [url, seturl] = useState("");

  const {
    data: routes,
    loading: routesLoading,
    error: routesError,
    refetch: routesRefetch,
  } = useFetch("routes");

  const {
    data: customers,
    loading: customersLoading,
    error: customersError,
    refetch: customersRefetch,
  } = useFetch(url);

  const {
    data: products,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useFetch("products/productsForOrder");

  // if order ID available get order
  const {
    data: existingOrder,
    loading: existingOrderLoading,
    error: existingOrderError,
  } = useFetch(
    location.state?.orderID
      ? `orders/orderwithdetails/${location.state.orderID}`
      : ""
  );
  const [orderCustomer, setOrderCustomer] = useState(
    !location.state?.orderID ? location.state : null
  );
  const [orderItems, setOrderItems] = useState(null);

  const [route, setRoute] = useState(null);
  const handleSelection = (e) => {
    if (!route) {
      seturl(`customers/byRoute/${e.routeID}?`);
      setRoute(e);
    } else {
      setOrderCustomer({ ...e, ...route });
    }
  };

  const onOrderComplete = () => {
    setOrderCustomer(null);
    setOrderItems(null);
  };

  useEffect(() => {
    if (existingOrder) {
      setOrderCustomer({ ...existingOrder.order, ...location.state });
      setOrderItems(existingOrder.orderDetails);
    }
  }, [existingOrder]);
  return (
    <div className="page-main">
      {routesLoading ? (
        <div style={{ textAlign: "center" }}>
          <Loader color={"black"} />
        </div>
      ) : orderCustomer === null ? (
        <div className="form-page">
          <PageTitle title={"New Order"} />
          <div
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "79vh",
              padding: "0 5px",
            }}
          >
            {routes && !route && (
              <List
                data={routes}
                keys={["routeID", "routeName"]}
                listHeader={"Routes"}
                selectedItem={handleSelection}
              />
            )}
            {route && customers && (
              <List
                data={customers}
                keys={["customerID", "customerName"]}
                listHeader={route.routeName + " Customers"}
                selectedItem={handleSelection}
                backBT={{ display: true, action: () => setRoute(null) }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <PageTitle title={"New Order"} />
          <CreateOrder
            orderDetailsData={orderCustomer}
            itemsData={orderItems || []}
            products={products?.products}
            categories={products?.categories}
            orderCompleted={onOrderComplete}
          />
        </>
      )}
    </div>
  );
}

const List = ({
  data,
  selectedItem,
  listHeader,
  keys,
  backBT = { display: false, action: () => {} },
}) => {
  const [search, setSearch] = useState("");

  return (
    data && (
      <div className="list-container">
        {data?.length > 0 ? (
          <>
            <div className="sticky">
              <Filter setSearch={setSearch} backBT={backBT} />
              <ListGroup horizontal className="list-header">
                <ListGroup.Item className="w-100">{listHeader}</ListGroup.Item>
              </ListGroup>
            </div>
            <div className="list-body">
              {data &&
                data.map(
                  (item) =>
                    (item[keys[1]].search(new RegExp(search, "i")) !== -1 ||
                      search === "") && (
                      <div key={item[keys[0]]}>
                        <ListGroup
                          horizontal
                          onClick={() => selectedItem(item)}
                        >
                          <ListGroup.Item className="w-100">
                            {item[keys[1]]}
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    )
                )}
            </div>
          </>
        ) : (
          <p className="text-center mt-4">No customers added</p>
        )}
      </div>
    )
  );
};
