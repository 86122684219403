import ListGroup from "react-bootstrap/ListGroup";
import DeleteBT from "../buttons/DeleteBT";
import { useState, useEffect } from "react";
import "./List.css";
import Filter from "../filter/Filter";
import { useDelete } from "../../db/dbHooks";

export default function UserList({ userData, setUserToEdit, setUserToDelete }) {
  const [search, setSearch] = useState("");
  const [clickedItem, setClickedItem] = useState(-1);

  return (
    <div className="list-main">
      <Filter setSearch={setSearch} />

      <ListGroup horizontal className="product-list list-header">
        <ListGroup.Item className="item1 w-75">Username</ListGroup.Item>
        <ListGroup.Item className="item2-3 w-25">Role</ListGroup.Item>
      </ListGroup>

      {userData &&
        userData.map((user, index) =>
          user.username.search(new RegExp(search, "i")) !== -1 ||
          search === "" ? (
            <>
              <ListGroup
                horizontal
                key={user.userID}
                onClick={() =>
                  setClickedItem(clickedItem === index ? -1 : index)
                }
              >
                <ListGroup.Item className="item1 w-75">
                  {user.username}
                </ListGroup.Item>
                <ListGroup.Item className="item2-3 w-25">
                  {user.userType}
                </ListGroup.Item>
              </ListGroup>
              {clickedItem === index && (
                <div>
                  <ListGroup>
                    <ListGroup.Item
                      className="modify-list-item"
                      onClick={() => setUserToEdit(user)}
                    >
                      {"Modify " + user.username}
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="delete-list-item"
                      onClick={() => setUserToDelete(user)}
                    >
                      {"Delete " + user.username}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              )}
            </>
          ) : (
            ""
          )
        )}
    </div>
  );
}
