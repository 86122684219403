import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { useFetch } from "../../db/dbHooks";
import Loader from "../loader/Loader";
const DispatchCustomersList = ({
  activeItem,
  setSelectedCustomer,
  updateView,
  setUpdateView,
}) => {
  const { data, loading, error, refetch } = useFetch(
    "orders/customersAndRoutes"
  );
  const [routes, setRoutes] = useState([]);

  //updateView
  useEffect(() => {
    if (updateView) {
      refetch();
      setUpdateView(false);
    }
  }, [updateView]);
  useEffect(() => {
    if (data) {
      console.log("runnign");
      let routeName = [];
      data.map((customer) => {
        routeName.push(customer.routeName);
      });
      routeName = routeName.filter(
        (item, index) => routeName.indexOf(item) === index
      );
      setRoutes(routeName);
    }
  }, [data]);

  return (
    <div className="text-center">
      {data
        ? routes.map((routeName, index) => (
            <div key={index}>
              <h5>{routeName}</h5>
              {data.map(
                (customer, index) =>
                  routeName === customer.routeName && (
                    <ListGroup
                      key={index}
                      onClick={() => setSelectedCustomer(customer)}
                    >
                      <ListGroup.Item
                        className="w-100"
                        active={
                          activeItem?.customer?.customerID ===
                          customer.customerID
                        }
                        style={{
                          backgroundColor:
                            (customer.status === "Completed" && "#79f77b") ||
                            (customer.status === "Processing" && "#f7cd79"),
                        }}
                      >
                        {customer.customerName}
                      </ListGroup.Item>
                    </ListGroup>
                  )
              )}
            </div>
          ))
        : loading && <Loader color={"black"} />}
    </div>
  );
};

export default DispatchCustomersList;
