import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import Category from "../../components/forms/Category";
import { useFetch, useDelete } from "../../db/dbHooks";
import Loader from "../../components/loader/Loader";
import CategoryList from "../../components/list/CategoryList";
import { ConfirmationPopup } from "../../components/floating/ConfirmationPopup.js";
import Popup from "../../components/floating/Popup.js";
export default function ManageCategories() {
  const [selectedTab, setSelectedTab] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [categoryToDelete, setcategoryToDelete] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("categories");

  const { data, loading, error, refetch } = useFetch("categories");

  useEffect(() => {
    setCategoryToEdit(null);
  }, [selectedTab]);

  useEffect(() => {
    (deleteResponse || deleteError) && setShowPopup(true);
  }, [deleteResponse, deleteError]);

  const onUpdate = () => {
    refetch();
    setCategoryToEdit(null);
  };

  const handleDelete = (id) => {
    deleteData(id);
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    deleteResponse && refetch();
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Categories"} />
      <FormTab
        tabs={["New Category", "Edit Category"]}
        selected={setSelectedTab}
      />

      {selectedTab === "New Category" ? (
        <Category reFetch={onUpdate} />
      ) : selectedTab === "Edit Category" && categoryToEdit === null ? (
        loading ? (
          <Loader color={"black"} />
        ) : (
          <div className="manage-list-container">
            <CategoryList
              categoryData={data}
              updateData={onUpdate}
              setcategoryToEdit={setCategoryToEdit}
              setcategoryToDelete={setcategoryToDelete}
            />
          </div>
        )
      ) : categoryToEdit ? (
        <Category categoryToEdit={categoryToEdit} reFetch={onUpdate} />
      ) : (
        ""
      )}

      {categoryToDelete && (
        <ConfirmationPopup
          setTrigger={() => setcategoryToDelete(null)}
          setAction={(e) => e && handleDelete(categoryToDelete.categoryID)}
          message={
            "Are you sure you want to delete " +
            categoryToDelete.categoryName +
            "?"
          }
        />
      )}
      {showPopup && (
        <Popup
          error={deleteError?.message}
          response={deleteResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </div>
  );
}
