import "./Dispatch.css";
import { useState, useEffect } from "react";
import DispatchCustomersList from "../../components/dispatch/DispatchCustomersList";
import DispatchQuantityList from "../../components/dispatch/DispatchQuantityList";
import DispatchCutomerMiddle from "../../components/dispatch/DispatchCutomerMiddle";
import DispatchProductMiddle from "../../components/dispatch/DispatchProductMiddle";

export default function Dispatch() {
  const [url, setUrl] = useState("");
  const [showList, setShowList] = useState();

  const [updateView, setUpdateView] = useState(false);
  const [middleViewData, setMiddleViewData] = useState({
    customer: null,
    product: null,
  });

  useEffect(() => {
    setShowList(0);
  }, [middleViewData]);

  return (
    <div className="dispatch-page">
      <div className="mobile-BT">
        <button
          className={showList === 1 && "active-bt"}
          onClick={() => setShowList(showList !== 1 ? 1 : 0)}
        >
          Products
        </button>
        <button
          className={showList === 2 && "active-bt"}
          onClick={() => setShowList(showList !== 2 ? 2 : 0)}
        >
          Customers
        </button>
      </div>
      <div>
        <div className={"dispatch-lists " + (showList !== 1 && "hidden-list")}>
          <h4>Quantity Overview </h4>
          <DispatchQuantityList
            activeItem={middleViewData}
            setclickedItem={(e) =>
              setMiddleViewData({ customer: null, product: e })
            }
          />
        </div>
      </div>
      <div className="dispatch-middle">
        {middleViewData.product && (
          <DispatchProductMiddle selectedProduct={middleViewData.product} />
        )}
        {middleViewData.customer && (
          <DispatchCutomerMiddle
            selectedCustomers={middleViewData.customer}
            setUpdateView={setUpdateView}
          />
        )}{" "}
      </div>

      <div>
        <div className={"dispatch-lists " + (showList !== 2 && "hidden-list")}>
          <h4>Customers</h4>

          <DispatchCustomersList
            activeItem={middleViewData}
            setSelectedCustomer={(e) =>
              setMiddleViewData({ customer: e, product: null })
            }
            updateView={updateView}
            setUpdateView={setUpdateView}
          />
        </div>
      </div>

      {
        //<ConfirmationPopup message="Unsaved changes. Are you sure you wnat to contunie?" />
      }
    </div>
  );
}
