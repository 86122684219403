export default function PageTitle({ title, subtitle }) {
  return (
    <div
      style={{ textAlign: "center", marginBottom: "10px", marginTop: "10px" }}
    >
      <h1>{title}</h1>
      <hr style={{ width: "90%", maxWidth: "200px", margin: "auto" }} />
      <h6>{subtitle}</h6>
    </div>
  );
}
