import { useState, useEffect, useContext } from "react";
import { userContext } from "../fun/userContext";
import { useCookies } from "react-cookie";

//const apiLink = process.env.api || "http://192.168.0.03:3001/api/";
//const apiLink = process.env.api || "http://localhost:3001/api/";

const apiLink = "https://api-nourfoods.seventhbyte.com/api/";
//delete request
export const useDelete = (url) => {
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const user = useContext(userContext);

  const deleteData = async (id) => {
    console.log("delete request");
    setDeleteLoading(true);
    setDeleteError(null);
    setDeleteResponse(null);
    try {
      const res = await fetch(`${apiLink + url}/${id}`, {
        headers: {
          authorization: user.token,
        },
        method: "DELETE",
      });
      if (!res.ok) {
        const jsonResponse = await res.json();
        throw jsonResponse.message
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const result = await res.json();
      setDeleteResponse(result);
    } catch (err) {
      typeof err !== "object"
        ? setDeleteError(err)
        : setDeleteError("Failed to connect to the serve");
    } finally {
      setDeleteLoading(false);
    }
  };

  return { deleteResponse, deleteLoading, deleteError, deleteData };
};

//get request
export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = useContext(userContext);

  const fetchData = async () => {
    console.log("Fetch request" + url);

    setLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await fetch(apiLink + url, {
        headers: {
          authorization: user.token,
        },
      });
      if (!response.ok) {
        const jsonResponse = await response.json();
        throw jsonResponse.message
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const data = await response.json();
      setData(data);
    } catch (err) {
      typeof err !== "object"
        ? setError(err)
        : setError("Failed to connect to the serve");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (url !== "") fetchData();
  }, [url]);

  return { data, loading, error, refetch: fetchData };
};

//post request
export const usePost = (url) => {
  const [postResponse, setPostResponse] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);
  const [cookies, removeCookie] = useCookies(["token"]);

  const user = useContext(userContext);

  const postData = async (data) => {
    console.log("Post request");

    setPostLoading(true);
    setPostError(null);
    setPostResponse(null);
    try {
      const res = await fetch(apiLink + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: user.token || cookies.token,
        },

        body: JSON.stringify({ ...data, userID: user.userID }),
      });
      if (!res.ok) {
        const jsonResponse = await res.json();
        throw jsonResponse
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const jsonResponse = await res.json();
      setPostResponse(jsonResponse);
    } catch (err) {
      typeof err !== "object"
        ? setPostError(err)
        : setPostError("Failed to connect to the serve");
    } finally {
      setPostLoading(false);
    }
  };

  return { postResponse, postLoading, postError, postData };
};

//update request
export const useUpdate = (url) => {
  const [updateResponse, setResponse] = useState(null);
  const [updateLoading, setupdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const user = useContext(userContext);

  const updateData = async (id, data) => {
    console.log("Update request", data);

    setupdateLoading(true);
    setUpdateError(null);
    setResponse(null);

    try {
      const res = await fetch(`${apiLink + url}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: user.token,
        },
        body: JSON.stringify({ ...data, userID: user.userID }),
      });
      if (!res.ok) {
        const jsonResponse = await res.json();
        throw jsonResponse
          ? jsonResponse.message
          : "Network response was not ok";
      }
      const result = await res.json();
      setResponse(result);
    } catch (err) {
      typeof err !== "object"
        ? setUpdateError(err)
        : setUpdateError("Failed to connect to the serve");
    } finally {
      setupdateLoading(false);
    }
  };

  return { updateResponse, updateLoading, updateError, updateData };
};
