import { PlusLg, DashLg } from "react-bootstrap-icons";
import { useRef } from "react";

export default function QtyEditor({
  productName = "",
  value,
  setValue,
  floating = false,
  button,
}) {
  const qtyRef = useRef(null);

  return (
    <div className={floating && "fixed-bg"}>
      <div
        className={floating && "fixed-inner"}
        style={{ height: "fit-content" }}
      >
        <div className="d-flex flex-column w-100 text-center">
          <h4>{productName + " "}Quantity</h4>

          <div className="d-flex justify-content-center align-items-center">
            <DashLg
              size={"2rem"}
              onClick={() => value > 0 && setValue(Number(value - 1))}
            />
            <input
              className="text-center"
              style={{
                border: 0,
                fontSize: "2rem",
                fontWeight: 600,
                width: "30%",
              }}
              ref={qtyRef}
              onFocus={() => qtyRef.current.select()}
              type="number"
              value={Number(value)}
              onChange={(e) => setValue(Number(e.target.value))}
            />
            <PlusLg size={"2rem"} onClick={() => setValue(Number(value + 1))} />
          </div>
          <div className="PB-range-slider-div">
            <input
              type="range"
              max={100}
              min={0}
              value={Number(value)}
              onChange={(e) => setValue(Number(e.target.value))}
              className="PB-range-slider"
              id="myRange"
            />
          </div>
        </div>
        {button}
      </div>
    </div>
  );
}
