import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect, useState } from "react";
import { useFetch, usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import Popup from "../floating/Popup";

export default function Customer({ customerToEdit = null, triggerUpdate }) {
  const [routes, setRoutes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [customer, setCustomer] = useState(
    customerToEdit
      ? customerToEdit
      : {
          customerName: "",
          customerAddress: "",
          customerEirCode: "",
          routeID: "",
          customerNumber: "",
          customerEmail: "",
        }
  );
  const { data, loading, error, refetch } = useFetch("routes");
  const { postResponse, postLoading, postError, postData } =
    usePost("customers");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("customers");

  useEffect(() => {
    data && setRoutes(data);

    if (postError || updateError) {
      setShowPopup(true);
    } else if (postResponse || updateResponse) {
      resetState();

      setShowPopup(true);
    } else if (!customerToEdit) {
      resetState();
    }
  }, [
    data,
    customerToEdit,
    postResponse,
    updateResponse,
    postError,
    updateError,
  ]);

  const closePopup = () => {
    setShowPopup(false);
    !errorMessage && triggerUpdate();
    setErrorMessage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (customerToEdit) {
        if (JSON.stringify(customerToEdit) !== JSON.stringify(customer)) {
          updateData(customer.customerID, customer);
        } else {
          setErrorMessage("No Changes Were Made");
          setShowPopup(true);
        }
      } else {
        postData(customer);
      }
    }
    setValidated(true);
  };

  const resetState = (updateData) => {
    updateData && triggerUpdate();
    setValidated(false);
    setCustomer({
      customerName: "",
      customerAddress: "",
      customerEirCode: "",
      routeID: "",
      customerNumber: "",
      customerEmail: "",
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Customer Name</Form.Label>
        <Form.Control
          id="customerName"
          required
          type="text"
          placeholder="Enter customer name"
          value={customer.customerName}
          onChange={(e) =>
            setCustomer({ ...customer, customerName: e.target.value })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Address</Form.Label>
        <Form.Control
          placeholder="Address"
          id="adderssAndCity"
          value={customer.customerAddress}
          onChange={(e) =>
            setCustomer({ ...customer, customerAddress: e.target.value })
          }
        />
      </Form.Group>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            id="customerEmailAddress"
            placeholder="example@ext.com"
            type="email"
            value={customer.customerEmail}
            onChange={(e) =>
              setCustomer({ ...customer, customerEmail: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Eircode</Form.Label>
          <Form.Control
            id="customerZipcode"
            placeholder="F35 ER65"
            required
            type="text"
            value={customer.customerEirCode}
            onChange={(e) =>
              setCustomer({ ...customer, customerEirCode: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Route</Form.Label>
          <Form.Select
            id="customerCounty"
            value={customer.routeID}
            required
            isInvalid={validated && customer.routeID === ""}
            onChange={(e) =>
              setCustomer({ ...customer, routeID: e.target.value })
            }
          >
            <option value={""}>Choose Route</option>
            {routes.map((route) => (
              <option key={route.routeID} value={route.routeID}>
                {route.routeName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            id="customerNumber"
            required
            placeholder="Enter phone number"
            type="number"
            value={customer.customerNumber}
            onChange={(e) =>
              setCustomer({ ...customer, customerNumber: e.target.value })
            }
          />
        </Form.Group>
      </Row>

      <SubmitBT
        loading={updateLoading || postLoading}
        BTname={customerToEdit ? "Update Customer" : "Add Customer"}
      />
      {showPopup && (
        <Popup
          error={postError || updateError || errorMessage}
          response={postResponse?.message || updateResponse?.message}
          setTrigger={closePopup}
        />
      )}
    </Form>
  );
}
