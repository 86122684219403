import { useState, useEffect, memo } from "react";
import "./CreateOrder.css";
import { ArrowLeftShort, PlusLg, DashLg, X } from "react-bootstrap-icons";
import ListGroup from "react-bootstrap/ListGroup";
import SubmitBT from "../buttons/SubmitBT";
import QtyEditor from "./QtyEditor";
export default memo(function ProductPicker({
  showList,
  setShowList,
  productData,
  categoryData,
  selectedProduct,
}) {
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    setCategory(null);
    setProduct(null);
    setQty(1);
  }, [showList]);

  const buttonClick = () => {
    let merged = { ...category, ...product, quantity: qty };
    selectedProduct(merged);
    setShowList(false);
  };
  return (
    showList && (
      <div className="fixed-bg">
        <div className="product-picker">
          <ProductDisplay
            category={category}
            product={product}
            qty={qty}
            backClick={() => (product ? setProduct(null) : setCategory(null))}
            exitClick={() => setShowList(false)}
          />
          {(product === null || category === null) && (
            <div className="product-picker-list">
              {!category && (
                <CategoryList
                  categories={categoryData}
                  selectedItem={setCategory}
                />
              )}
              {product === null && category !== null && (
                <ProductList
                  category={category}
                  products={productData.filter(
                    (item) => item.categoryID === category.categoryID
                  )}
                  selectedItem={setProduct}
                />
              )}
            </div>
          )}

          {category !== null && product !== null && (
            <>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <QtyEditor value={qty} setValue={setQty} />
              </div>
              <SubmitBT BTname="Add Item" onClick={buttonClick} />
            </>
          )}
        </div>
      </div>
    )
  );
});

const ProductDisplay = ({
  category = null,
  product = null,
  qty = null,
  backClick,
  exitClick,
}) => {
  return (
    <div className="d-flex ">
      {
        <ArrowLeftShort
          size={40}
          style={{ margin: "auto" }}
          hidden={category ? false : true}
          onClick={backClick}
        />
      }
      <div
        style={{
          width: "80%",
          height: "35px",
          flexGrow: "10",
          fontWeight: 600,
          alignContent: "center",
        }}
      >
        {category && category.categoryName + " "}
        {product && product.productName + "-" + qty + product?.unit}
      </div>
      {<X size={40} style={{ margin: "auto" }} onClick={exitClick} />}
    </div>
  );
};

const CategoryList = ({ categories = [], selectedItem }) => {
  return (
    <>
      <ListGroup className="list-header sticky">
        <ListGroup.Item className="w-100 "> Category Name </ListGroup.Item>
      </ListGroup>
      {categories &&
        categories.map((category) => (
          <ListGroup horizontal key={category.categoryID}>
            <ListGroup.Item
              className="w-100"
              onClick={() => selectedItem(category)}
            >
              {" "}
              {category.categoryName}
            </ListGroup.Item>
          </ListGroup>
        ))}
    </>
  );
};

const ProductList = ({ category, products = [], selectedItem }) => {
  return (
    <>
      <ListGroup horizontal className="list-header sticky">
        <ListGroup.Item className="w-60 "> Product Name </ListGroup.Item>
        <ListGroup.Item className="w-20 "> Unit </ListGroup.Item>
        <ListGroup.Item className="w-20 "> Price </ListGroup.Item>
      </ListGroup>
      {products &&
        products.map((product) => (
          <ListGroup
            horizontal
            key={product.productID}
            onClick={() => selectedItem(product)}
          >
            <ListGroup.Item className="w-60 ">
              {category.categoryName + " " + product.productName}
            </ListGroup.Item>
            <ListGroup.Item className="w-20 "> {product.unit} </ListGroup.Item>
            <ListGroup.Item className="w-20 ">
              {" "}
              {Number(product.price)}{" "}
            </ListGroup.Item>
          </ListGroup>
        ))}
    </>
  );
};
