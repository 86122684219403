import SubmitBT from "../../components/buttons/SubmitBT";
import Popup from "../../components/floating/Popup";
import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { useFetch, useUpdate } from "../../db/dbHooks";
import { getFromLocalDB, storeInLocalDB } from "../../fun/fun";
import QtyEditor from "../../components/createOder/QtyEditor";
import BatchEditor from "./BatchEditor";

const DispatchCutomerMiddle = ({ selectedCustomers, setUpdateView }) => {
  const [addBatchNo, setAddBatchNo] = useState(null);
  const [qtyToEdit, setQtyToEdit] = useState(null);
  const [popup, setPopup] = useState({ trigger: false });
  const [clickedItem, setClickedItem] = useState(-1);
  const [orderDetails, setOrderDetails] = useState(null);
  const { data, loading, error, refetch } = useFetch(
    `orders/orderwithdetails/${selectedCustomers.orderID}`
  );
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("dispatch");
  const {
    updateResponse: orderStatusResponse,
    updateLoading: orderStatusLoading,
    updateError: orderStatusError,
    updateData: updateOrderStatus,
  } = useUpdate("orders/updateOrderStatus");
  useEffect(() => {
    data && setOrderDetails(data.orderDetails);
  }, [data]);
  useEffect(() => {
    updateError && setPopup({ trigger: true, error: updateError });
    if (updateResponse) {
      refetch();
      setUpdateView(true);
      setPopup({ trigger: true, response: updateResponse.message });
    }
    if (orderStatusResponse) {
      refetch();
      setUpdateView(true);

      setPopup({ trigger: true, response: orderStatusResponse.message });
    }
  }, [updateResponse, updateError, orderStatusResponse]);

  const handleSubmit = (e, completeOrder = false) => {
    let itemsToUpdate = [];
    let total = 0;
    let patchNotEntered = 0;

    orderDetails.map((item) => {
      if (!item.batch && !item.outOfStock && completeOrder) {
        patchNotEntered += 1;
      }
      if (
        item.editedBatch === true ||
        item.editedQuantity === true ||
        item.edited ||
        (!item.dispatchQTY && completeOrder)
      ) {
        itemsToUpdate.push({
          dispatchQTY: item.dispatchQTY ? item.dispatchQTY : item.quantity,
          batch: item.batch,
          outOfStock: item.outOfStock,
          orderDetailsID: item.orderDetailsID,
        });
      }
      if (!item.outOfStock) {
        total +=
          item.price * (item.dispatchQTY ? item.dispatchQTY : item.quantity);
      }
    });
    if (
      (itemsToUpdate.length > 0 && !completeOrder) ||
      (completeOrder && patchNotEntered === 0)
    ) {
      updateData("", {
        orderData: {
          total: total,
          orderID: selectedCustomers.orderID,
          status: completeOrder ? "Completed" : "Processing",
        },
        updatedOrderItems: itemsToUpdate,
      });
    } else if (patchNotEntered > 0) {
      setPopup({
        trigger: true,
        error: patchNotEntered + " item missing batch",
      });
    } else {
      setPopup({ trigger: true, error: "No changes were made" });
    }
  };
  const handleMarkComplete = () => {
    let patchNotEntered = 0;
    let saveOrder = false;
    orderDetails.map((item) => {
      if (!item.batch && !item.outOfStock) {
        patchNotEntered += 1;
      }
      if (
        item.editedQuantity ||
        item.editedPatch ||
        item.edited ||
        !item.dispatchQTY
      ) {
        saveOrder = true;
      }
    });
    if (patchNotEntered === 0 && !saveOrder) {
      updateOrderStatus(selectedCustomers.orderID, { status: "Completed" });
    } else if (saveOrder && patchNotEntered === 0) {
      handleSubmit("", true);
    } else {
      setPopup({
        trigger: true,
        error: patchNotEntered + " item missing batch",
      });
    }
  };
  const handleOutOfStock = (product) => {
    setOrderDetails(
      orderDetails.map((item) => {
        if (item.productID !== product.productID) {
          return item;
        } else {
          return {
            ...product,
            outOfStock: item.outOfStock ? false : true,
            edited: true,
          };
        }
      })
    );
    setClickedItem(-1);
  };
  const handlePatchUpdate = (product) => {
    if (product) {
      setOrderDetails(
        orderDetails.map((item) => {
          if (item.productID !== product.productID) {
            return item;
          } else {
            return product;
          }
        })
      );
      setAddBatchNo(null);
    } else {
      setAddBatchNo(null);
    }
  };

  const handleQtyUpdate = (product) => {
    if (product) {
      setOrderDetails(
        orderDetails.map((item) => {
          if (item.productID !== product.productID) {
            return item;
          } else {
            return product;
          }
        })
      );
      setQtyToEdit(null);
    } else {
      setQtyToEdit(null);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "650px",
        backgroundColor: "#ececec",
        padding: "5px",
        borderRadius: 15,
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <h3>{selectedCustomers.customerName}</h3>
      <h6 style={{ textAlign: "left", width: "100%" }}>
        {"Order Status: " + (data && data.order.status)}
      </h6>

      <div
        style={{
          width: "100%",
          maxHeight: "40vh",
          overflowY: "auto",
        }}
      >
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="w-50">Product</ListGroup.Item>
          <ListGroup.Item className="w-25">Batch</ListGroup.Item>
          <ListGroup.Item className="w-25">Quantity</ListGroup.Item>
        </ListGroup>
        {orderDetails &&
          orderDetails.map((item, index) => (
            <div key={item.orderDetailsID}>
              <ListGroup horizontal>
                <ListGroup.Item
                  className="w-50"
                  style={{ backgroundColor: item.outOfStock && "#d80000" }}
                  onClick={() =>
                    setClickedItem(clickedItem === index ? -1 : index)
                  }
                >
                  {item.categoryName + " " + item.productName}
                </ListGroup.Item>
                <ListGroup.Item
                  className={item.editedBatch ? "w-25 edited" : "w-25"}
                  onClick={() => setAddBatchNo(item)}
                >
                  {item.batch}
                </ListGroup.Item>
                <ListGroup.Item
                  className={item.editedQuantity ? "w-25 edited" : "w-25"}
                  onClick={() => setQtyToEdit(item)}
                >
                  {item.dispatchQTY
                    ? parseFloat(item.dispatchQTY)
                    : parseFloat(item.quantity)}
                </ListGroup.Item>
              </ListGroup>
              {clickedItem === index && (
                <div>
                  <ListGroup>
                    {/*  <ListGroup.Item className="modify-list-item">
                      {"Change Item"}
                    </ListGroup.Item> */}
                    <ListGroup.Item
                      className="delete-list-item"
                      onClick={() => handleOutOfStock(item)}
                    >
                      {item.outOfStock ? "Mark Available" : "Mark Out Of Stock"}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              )}
            </div>
          ))}
      </div>
      {data?.order.note && (
        <div style={{ width: "100%" }}>
          <p className="mb-0">Customer Note:</p>
          <ListGroup>
            <ListGroup.Item style={{ maxHeight: "63px", overflowY: "auto" }}>
              {data.order.note}
            </ListGroup.Item>
          </ListGroup>
        </div>
      )}
      <div style={{ display: "flex", width: "100%", columnGap: "20px" }}>
        <SubmitBT
          BTname="Save Changes"
          onClick={handleSubmit}
          loading={updateLoading}
        />
        <SubmitBT
          BTname="Complete Order"
          onClick={() => handleSubmit("", true)}
          loading={updateLoading}
          style={{ backgroundColor: "green" }}
        />
      </div>
      {addBatchNo && (
        <BatchEditor productData={addBatchNo} onSubmit={handlePatchUpdate} />
      )}
      {qtyToEdit && (
        <FloatingQtyEditor item={qtyToEdit} onSubmit={handleQtyUpdate} />
      )}
      {popup.trigger && (
        <Popup
          error={popup.error}
          response={popup.response}
          setTrigger={() => setPopup({ trigger: false })}
        />
      )}
    </div>
  );
};

const FloatingQtyEditor = ({ item, onSubmit }) => {
  const [product, setProduct] = useState(item);

  return (
    <div className="fixed-bg" onClick={() => onSubmit(null)}>
      <div
        className="fixed-inner"
        style={{ height: "fit-content" }}
        onClick={preventBubbling}
      >
        <h5 style={{ textAlign: "center" }}>{product.customerName}</h5>
        <QtyEditor
          productName={product.categoryName + " " + product.productName}
          value={
            product.dispatchQTY
              ? Number(product.dispatchQTY)
              : Number(product.quantity)
          }
          setValue={(e) =>
            setProduct({
              ...product,
              dispatchQTY: Number(e),
              editedQuantity: true,
            })
          }
        />
        <h6 style={{ textAlign: "center" }}>
          Customer Quantity: {Number(product.quantity)}
        </h6>
        <SubmitBT BTname="Set Quantity" onClick={() => onSubmit(product)} />
      </div>
    </div>
  );
};

const preventBubbling = (event) => {
  event.stopPropagation();
};

export default DispatchCutomerMiddle;
