import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect, memo } from "react";
import "./CreateOrder.css";

export default function OrderList({
  items,
  itemToRemove,
  qtyToEdit,
  productToAdd,
  priceToEdit,
  showProductList,
  previousProducts,
}) {
  const [orderItems, setOrderItems] = useState([]);
  const [showActionList, setShowActionList] = useState(-1);

  useEffect(() => {
    setOrderItems(items);
    setShowActionList(null);
  }, [items]);

  return (
    <div className="order-list-container">
      <div className="order-list">
        <ListHeadings />
        {orderItems.length > 0 &&
          orderItems.map((item, index) => (
            <>
              <ListGroup horizontal key={item.productID}>
                <ListGroup.Item
                  className="item-name"
                  onClick={() =>
                    setShowActionList(index === showActionList ? null : index)
                  }
                >
                  {item.categoryName + " " + item.productName}
                </ListGroup.Item>
                <ListGroup.Item className="item-qty">
                  {item.unit}
                </ListGroup.Item>
                <ListGroup.Item
                  className="item-qty"
                  onClick={() => qtyToEdit(item)}
                >
                  {Number(item.quantity)}
                </ListGroup.Item>
                <ListGroup.Item
                  className="item-qty"
                  onClick={() => priceToEdit(item)}
                >
                  {Number(item.price)}
                </ListGroup.Item>
              </ListGroup>
              <ActionList
                trigger={showActionList === index}
                setTrigger={(e) => setShowActionList(e)}
                deleteItem={() => itemToRemove(item)}
              />
            </>
          ))}
        {previousProducts && (
          <PreviousProductsList
            previousProducts={previousProducts}
            clickedProduct={productToAdd}
          />
        )}{" "}
        <FillEmpty />
      </div>
      <button
        onClick={() => showProductList()}
        style={{
          fontSize: "30px",
          position: "absolute",
          zIndex: 2,
          height: "50px",
          width: "50px",
          bottom: "40px",
          right: "40px",
          borderRadius: "50%",
          border: 0,
          color: "#ffffff",
          backgroundColor: "#000000",
        }}
      >
        +
      </button>
    </div>
  );
}

const ListHeadings = () => {
  return (
    <div className="list-header">
      <ListGroup horizontal>
        <ListGroup.Item className="item-name">Item Name</ListGroup.Item>
        <ListGroup.Item className="item-qty">Unit</ListGroup.Item>
        <ListGroup.Item className="item-qty">Qty</ListGroup.Item>
        <ListGroup.Item className="item-qty">€</ListGroup.Item>
      </ListGroup>
    </div>
  );
};

const ActionList = ({ trigger = false, setTrigger, deleteItem }) => {
  const handleClick = () => {
    setTrigger(null);
    deleteItem();
  };
  return (
    <ListGroup hidden={!trigger}>
      <ListGroup.Item
        className="bg-danger text-white text-center"
        onClick={() => handleClick()}
      >
        Delete Item
      </ListGroup.Item>
    </ListGroup>
  );
};

const PreviousProductsList = ({ previousProducts, clickedProduct }) => {
  return (
    previousProducts.length > 0 && (
      <>
        <ListGroup>
          <ListGroup.Item className="w-100 text-center previous-product">
            ****Previous Order****
          </ListGroup.Item>
        </ListGroup>
        {previousProducts.map((item) => (
          <>
            <ListGroup
              horizontal
              key={item.productID}
              onClick={() => clickedProduct(item)}
            >
              <ListGroup.Item className="item-name previous-product">
                {item.categoryName + " " + item.productName}
              </ListGroup.Item>
              <ListGroup.Item className="item-qty previous-product">
                {item.unit}
              </ListGroup.Item>
              <ListGroup.Item className="item-qty previous-product">
                {Number(item.quantity)}
              </ListGroup.Item>
              <ListGroup.Item className="item-qty previous-product">
                {Number(item.price)}
              </ListGroup.Item>
            </ListGroup>
            <ActionList />
          </>
        ))}
      </>
    )
  );
};

const FillEmpty = memo(function FillEmpty() {
  const [emptyItems, setEmptyItems] = useState([
    -1, -2, -3, -4, -5, -6, -7, -8, -9, -15, -11, -12, -13, -14,
  ]);

  return (
    <>
      {emptyItems.map((item) => (
        <ListGroup horizontal key={item + 100}>
          <ListGroup.Item
            className="item-name"
            style={{ color: "#ffffff00" }}
          ></ListGroup.Item>
          <ListGroup.Item className="item-qty" style={{ color: "#ffffff00" }}>
            .
          </ListGroup.Item>
          <ListGroup.Item className="item-qty" style={{ color: "#ffffff00" }}>
            .
          </ListGroup.Item>
          <ListGroup.Item className="item-qty" style={{ color: "#ffffff00" }}>
            .
          </ListGroup.Item>
        </ListGroup>
      ))}
    </>
  );
});
