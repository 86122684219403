import "./App.css";
import Login from "./pages/login/Login";
import NavBar from "./components/navBar/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ManageProducts from "./pages/manage/ManageProduts";
import Home from "./pages/home/Home";
import ManageRoutes from "./pages/manage/ManageRoutes";
import ManageCategories from "./pages/manage/ManageCategories";
import ManageCustomers from "./pages/manage/ManageCustomers";
import NewOrder from "./pages/order/NewOrder";
import OrderHistory from "./pages/order/OrderHistory";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import ManageUsers from "./pages/manage/ManageUsers";
import Profile from "./pages/login/Profile";
import { userContext } from "./fun/userContext.js";
import Dispatch from "./pages/dispatch/Dispatch.js";
import Report from "./pages/dispatch/Report.js";
import ProtectedRoute from "./Routes/ProtectedRoute .js";
import ManageVans from "./pages/manage/ManageVans.js";
import "./BootstrapStyle.scss";
import CheckOnlineStatus from "./components/navBar/CheckOnlineStatus.js";
import QtyEditor from "./components/createOder/QtyEditor.js";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, removeCookie] = useCookies(["token"]);
  const localDBuser = JSON.parse(localStorage.getItem("NF_DB_USER"));
  const [user, setUser] = useState(
    localDBuser && cookies.token !== "undefined"
      ? { ...localDBuser, token: cookies.token }
      : null
  );

  const signin = () => {
    setIsAuthenticated(true);
  };

  const signout = () => {
    setUser(null);
    localStorage.setItem("NF_DB_USER", null);
    setIsAuthenticated(false);
    removeCookie("token");
  };

  //TODO: extend the user login
  useEffect(() => {
    if (user) {
      const timeUntilExpiry = new Date(user.expDate * 1000) - Date.now();
      if (timeUntilExpiry <= 1000) {
        signout();
      } else {
        signin();
      }
      const logoutTimer = setTimeout(signout, timeUntilExpiry);
      return () => {
        clearTimeout(logoutTimer);
      };
    }
  }, [user]);

  const DefaultPage = (key) => {
    switch (key) {
      case "Admin":
        return <Home />;
      case "Order":
        return <Home />;

      case "Dispatch":
        return <Dispatch />;
    }
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <userContext.Provider value={user}>
          <NavBar />
          <CheckOnlineStatus />
          <Routes>
            <Route path="*" element={DefaultPage(user.userType)} />
            {user.userType !== "Dispatch" && user.userType !== "Driver" && (
              <>
                <Route path="/" element={<Home user={user} />} />
                <Route path="/ManageRoutes" element={<ManageRoutes />} />
                <Route path="/ManageProducts" element={<ManageProducts />} />
                <Route
                  path="/ManageCategories"
                  element={<ManageCategories />}
                />
                <Route path="/ManageCustomers" element={<ManageCustomers />} />
                <Route path="/ManageVans" element={<ManageVans />} />
                <Route path="/NewOrder" element={<NewOrder />} />
                <Route path="/OrderHistory" element={<OrderHistory />} />
                <Route path="/Reports" element={<Report />} />
              </>
            )}
            {user.userType !== "Driver" && (
              <Route path="/Dispatch" element={<Dispatch />} />
            )}

            {user.userType === "Admin" && (
              <Route path="/ManageUsers" element={<ManageUsers />} />
            )}
            <Route path="/Profile" element={<Profile userData={user} />} />
          </Routes>
        </userContext.Provider>
      ) : (
        <Login setUser={(e) => setUser(e)} />
      )}
    </div>
  );
}
export default App;
