import FormTab from "../../components/forms/FormTab.js";
import PageTitle from "../../components/pageTitle/PageTitle.js";
import { useState, useEffect } from "react";
import Register from "../../components/forms/Register .js";
import { useFetch, useDelete } from "../../db/dbHooks";
import { ConfirmationPopup } from "../../components/floating/ConfirmationPopup.js";
import Popup from "../../components/floating/Popup.js";
import UserList from "../../components/list/UsersList.js";

export default function ManageUsers() {
  const { data, loading, error, refetch } = useFetch("auth/");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("users");
  const [selectedTab, setSelectedTab] = useState(null);
  const [users, setusers] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setUserToEdit(null);
  }, [selectedTab]);

  useEffect(() => {
    (deleteResponse || deleteError) && setShowPopup(true);
  }, [deleteResponse, deleteError]);

  const update = () => {
    refetch();
    setUserToEdit(null);
  };

  const handleDelete = (id) => {
    deleteData(id);
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    deleteResponse && refetch();
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Users"} />
      <FormTab tabs={["New User", "Edit User"]} selected={setSelectedTab} />

      {selectedTab === "New User" ? (
        <Register reFetch={update} />
      ) : selectedTab === "Edit User" && userToEdit === null ? (
        <UserList
          userData={data}
          setUserToEdit={setUserToEdit}
          setUserToDelete={setUserToDelete}
        />
      ) : userToEdit ? (
        <Register userToEdit={userToEdit} reFetch={update} />
      ) : (
        ""
      )}

      {userToDelete && (
        <ConfirmationPopup
          setTrigger={() => setUserToDelete(null)}
          setAction={(e) => e && handleDelete(userToDelete.userID)}
          message={
            "Are you sure you want to delete " + userToDelete.userName + "?"
          }
        />
      )}
      {showPopup && (
        <Popup
          error={deleteError?.message}
          response={deleteResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </div>
  );
}
