import { Filter as FilterIcon, ArrowLeft } from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import "./Filter.css";
export default function Filter({
  data,
  setSearch,
  filter = false,
  backBT = { display: false, action: () => {} },
}) {
  const [showList, setShowList] = useState(false);

  return (
    <div className="group" style={{ backgroundColor: "white" }}>
      {backBT.display && (
        <ArrowLeft
          fontSize={"35"}
          fontWeight={"bold"}
          onClick={backBT.action}
        />
      )}
      <div style={{ width: "100%" }}>
        <svg viewBox="0 0 24 24" aria-hidden="true" className="searchIcon">
          <g>
            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
          </g>
        </svg>
        <input
          className="input"
          type="search"
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div>
        {filter && (
          <FilterIcon fontSize={"42"} onClick={() => setShowList(!showList)} />
        )}{" "}
        {showList && data && (
          <div className="filter-list" id="dropdownList">
            {data &&
              data.map((item) => (
                <p
                  onClick={() => {
                    setSearch(item);
                    setShowList(false);
                  }}
                >
                  {item}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
