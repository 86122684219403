import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState, useEffect } from "react";
import { usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import Popup from "../floating/Popup";

export default function Route({ routeToEdit = null, triggerUpdate }) {
  const [popup, setPopup] = useState({ trigger: false });
  const [errorMessage, setErrorMessage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [route, setRoute] = useState(
    routeToEdit ? routeToEdit : { routeName: "", days: [] }
  );
  var days = [];
  const checkBoxes = [
    { ID: "Sun", Value: 1, Checked: false },
    { ID: "Mon", Value: 2, Checked: false },
    { ID: "Tue", Value: 3, Checked: false },
    { ID: "Wed", Value: 4, Checked: false },
    { ID: "Thu", Value: 5, Checked: false },
    { ID: "Fri", Value: 6, Checked: false },
    { ID: "Sat", Value: 7, Checked: false },
  ];
  const [checkboxList, setCheckboxList] = useState(checkBoxes);

  const { postResponse, postLoading, postError, postData } = usePost("routes");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("routes");

  useEffect(() => {
    if (updateResponse || postResponse) {
      setValidated(false);
      setPopup({
        trigger: true,
        response: postResponse?.message || updateResponse?.message,
        error: "",
      });
      //resetState(true);
    } else if (updateError || postError) {
      setPopup({
        trigger: true,
        response: "",
        error: updateError || postError,
      });
    }

    !routeToEdit && resetState(false);
    routeToEdit && setCheckedBoxes();
  }, [routeToEdit, postResponse, updateResponse, postError, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    days = getCheckedBoxs();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (days.length > 0) {
        if (routeToEdit) {
          if (
            JSON.stringify(routeToEdit) !==
            JSON.stringify({ ...route, days: days })
          ) {
            updateData(0, {
              route: {
                routeID: route.routeID,
                routeName: route.routeName,
                days: days,
              },
            });
          } else {
            setPopup({ trigger: true, error: "No changes were made." });
          }
        } else {
          postData({
            route: { ...route, days: getCheckedBoxs() },
          });
        }
      } else {
        setPopup({
          trigger: true,
          error: "At least one day should be selected",
        });
      }
    }
    setValidated(true);
  };

  /*   const errorHandling = () => {
    let error = "";
    const daysPicked = getCheckedBoxs();
    if (
      routeToEdit &&
      JSON.stringify(routeToEdit) ===
        JSON.stringify({ ...route, days: getCheckedBoxs() })
    ) {
      error = "No Changes Were Made";
    } else if (route.routeName === "") {
      error = "Route Name Cannot Be Empty";
    } else if (daysPicked.length === 0) {
      error = "Route Must Have  At Least One Day Selected";
    }
    if (error !== "") {
      setPopup({ trigger: true, error });
      return true;
    } else {
      return false;
    }
  }; */

  const handleShowPopup = () => {
    console.log("rrr");
    setPopup({ trigger: false });
    triggerUpdate();
  };
  const resetState = (updateData) => {
    setRoute({
      routeName: "",
      routeDays: "",
    });
    setCheckboxList(checkBoxes);
  };

  function getCheckedBoxs() {
    let checked = [];
    checkboxList.map((item) => {
      if (item.Checked) checked.push(item.Value);
    });
    return checked;
  }
  function setCheckedBoxes() {
    let item = null;
    setCheckboxList(
      checkBoxes.map((box) => {
        item = box;
        route.days.map((day) => {
          if (box.Value === day) item = { ...box, Checked: true };
        });
        return item;
      })
    );
  }
  const handleCheck = (e) => {
    setCheckboxList(
      checkboxList.map((box) => {
        if (box.ID === e.target.id) {
          return { ...box, Checked: !box.Checked };
        } else {
          return box;
        }
      })
    );
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Route Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter route name"
          required
          id="routeName"
          value={route.routeName}
          onChange={(e) => setRoute({ ...route, routeName: e.target.value })}
        />
      </Form.Group>
      <Form.Label> Delivery Days</Form.Label>

      <Row>
        <Form.Group
          as={Col}
          className="d-flex flex-wrap justify-content-around"
        >
          {checkboxList.map((item) => (
            <Form.Check
              key={item.Value}
              inline
              label={item.ID}
              type="checkbox"
              id={item.ID}
              value={item.Value}
              checked={item.Checked}
              onChange={handleCheck}
            />
          ))}
        </Form.Group>
      </Row>

      <Row style={{ justifyContent: "space-around" }}>
        <SubmitBT
          loading={updateLoading || postLoading}
          BTname={routeToEdit ? "Update Route" : "Add Route"}
        />
      </Row>
      {popup.trigger && (
        <Popup
          error={popup.error}
          response={popup.response}
          setTrigger={handleShowPopup}
        />
      )}
    </Form>
  );
}
