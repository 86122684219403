import { useDelete, useFetch } from "../../db/dbHooks.js";
import ListGroup from "react-bootstrap/ListGroup";
import "./CreateOrder.css";
import { ArrowLeftShort, ThreeDotsVertical } from "react-bootstrap-icons";
import { ddmmyy } from "../../fun/fun.js";
import DropDown from "../dropdown/DropDown.js";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader.js";

export default function OrderView({ orderID, setTrigger, clearBg = false }) {
  const { data, loading, error, refetch } = useFetch(
    `orders/orderwithdetails/${orderID}`
  );

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className="fixed-bg"
      style={{ zIndex: 2000, backgroundColor: clearBg ? "#00000000" : "" }}
      onClick={setTrigger}
    >
      <div
        className="fixed-inner"
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          fontSize: "13px",
        }}
        onClick={preventBubbling}
      >
        {data ? (
          <>
            <Header
              order={data.order}
              setBackClick={setTrigger}
              orderID={orderID}
            />
            <OrderDetails order={data.order} />
            <OrderList orderDetails={data.orderDetails} order={data.order} />
          </>
        ) : (
          loading && <Loader color={"black"} style={{ alignSelf: "center" }} />
        )}
      </div>
    </div>
  );
}

const Header = ({ order, setBackClick, orderID }) => {
  const {
    date: deleteRes,
    loading: deleteLoading,
    error: deleteError,
    deleteData,
  } = useDelete("orders");
  const navigate = useNavigate();
  const dropDownOptions = [
    { value: "Eidt", label: "Eidt" },
    { value: "Delete", label: "Delete" },
  ];
  const handleDropDown = (option) => {
    switch (option.value) {
      case "Eidt":
        navigate("/newOrder", {
          state: {
            orderID,
          },
        });
        break;
      case "Delete":
        deleteData(orderID);
        break;
      default:
        break;
    }
  };
  return (
    <div className="d-flex ">
      {
        <ArrowLeftShort
          size={40}
          style={{ margin: "auto" }}
          onClick={setBackClick}
        />
      }
      <div
        style={{
          width: "80%",
          height: "35px",
          flexGrow: "10",
          fontWeight: 600,
          alignContent: "center",
          textAlign: "center",
          fontSize: "17px",
        }}
      >
        {order.customerName}
      </div>

      <DropDown
        Component={<ThreeDotsVertical size={25} />}
        options={dropDownOptions}
        onSelect={handleDropDown}
      />
    </div>
  );
};

const OrderDetails = ({ order }) => {
  return (
    order && (
      <div className="order-details-header">
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="w-50">
            {" "}
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"Date: "}</p>
              <p> {ddmmyy(order.orderDate)}</p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>Delivery: </p>
              <p>{ddmmyy(order.deliveryDate)}</p>
            </div>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup horizontal className="list-header">
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"Created By: "}</p>
              <p> {order.username}</p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="w-50">
            <div className="d-flex">
              <p style={{ fontWeight: 600 }}>{"Status:"}</p>
              <p> {" " + order.status}</p>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    )
  );
};

const OrderList = ({ orderDetails, order }) => {
  return (
    orderDetails && (
      <>
        <div style={{ height: "70%", overflowY: "auto" }}>
          <ListGroup horizontal className="list-header">
            <ListGroup.Item className="item-name-56">Product</ListGroup.Item>
            <ListGroup.Item className="list-item-style w-11">
              Unit
            </ListGroup.Item>
            <ListGroup.Item className="list-item-style w-11">
              Qty
            </ListGroup.Item>
            <ListGroup.Item className="list-item-style w-11">
              FQty
            </ListGroup.Item>
            <ListGroup.Item className="list-item-style w-11">€</ListGroup.Item>
          </ListGroup>
          {orderDetails.map((item) => (
            <ListGroup horizontal>
              <ListGroup.Item
                className="item-name-56"
                style={{ textDecoration: item.outOfStock && "line-through" }}
              >
                {item.categoryName + " " + item.productName}
              </ListGroup.Item>
              <ListGroup.Item className="list-item-style w-11">
                {item.unit}
              </ListGroup.Item>
              <ListGroup.Item className="list-item-style w-11">
                {Number(item.quantity)}
              </ListGroup.Item>
              <ListGroup.Item className="list-item-style w-11">
                {Number(item.dispatchQTY)}
              </ListGroup.Item>

              <ListGroup.Item className="list-item-style w-11">
                {Number(item.price)}
              </ListGroup.Item>
            </ListGroup>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "10%",
            alignItems: "center",
            marginRight: "15px",
            fontWeight: 600,
          }}
        >
          <div
            style={{
              width: "75%",
              fontSize: "11px",
              padding: "2px",
              overflowY: "auto",
            }}
          >
            Note: {order.note}
          </div>
          <div> Order Total: {Number(order.total)}€ </div>
        </div>
      </>
    )
  );
};
