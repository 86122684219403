import FormTab from "../../components/forms/FormTab";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useState, useEffect } from "react";
import Product from "../../components/forms/Product";
import { useFetch, useDelete } from "../../db/dbHooks";
import ProductList from "../../components/list/ProductList";
import { ConfirmationPopup } from "../../components/floating/ConfirmationPopup.js";
import Popup from "../../components/floating/Popup.js";

export default function ManageProducts() {
  const [selectedTab, setSelectedTab] = useState(null);
  const [prodcutToEdit, setProductToEdit] = useState(null);
  const [prodcutToDelete, setProductToDelete] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { data, loading, error, refetch } = useFetch("products");
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetch("categories");
  const { deleteResponse, deleteLoading, deleteError, deleteData } =
    useDelete("products");

  useEffect(() => {
    setProductToEdit(null);
  }, [selectedTab]);

  useEffect(() => {
    (deleteResponse || deleteError) && setShowPopup(true);
  }, [deleteResponse, deleteError]);

  const update = () => {
    refetch();
    setProductToEdit(null);
  };

  const handleDelete = (id) => {
    deleteData(id);
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    deleteResponse && refetch();
  };

  return (
    <div className="form-page">
      <PageTitle title={"Manage Products"} />
      <FormTab
        tabs={["New Product", "Edit Product"]}
        selected={setSelectedTab}
      />

      {selectedTab === "New Product" ? (
        <Product category={categoryData} reFetch={update} />
      ) : selectedTab === "Edit Product" && prodcutToEdit === null ? (
        <div className="manage-list-container">
          <ProductList
            productData={data}
            setProductToEdit={setProductToEdit}
            setProductToDelete={setProductToDelete}
          />
        </div>
      ) : prodcutToEdit ? (
        <Product
          category={categoryData}
          productToEdit={prodcutToEdit}
          reFetch={update}
        />
      ) : (
        ""
      )}

      {prodcutToDelete && (
        <ConfirmationPopup
          setTrigger={() => setProductToDelete(null)}
          setAction={(e) => e && handleDelete(prodcutToDelete.productID)}
          message={
            "Are you sure you want to delete " +
            prodcutToDelete.productName +
            "?"
          }
        />
      )}
      {showPopup && (
        <Popup
          error={deleteError?.message}
          response={deleteResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </div>
  );
}
