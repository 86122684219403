import "./CreateOrder.css";
import ProductPicker from "./ProductPicker";
import { useState, useEffect, useRef } from "react";
import OrderList from "./OrderList";
import { euDate, nextDeliveryDates, sqlDate } from "../../fun/fun";
import { usePost, useUpdate, useFetch } from "../../db/dbHooks.js";
import SubmitBT from "../buttons/SubmitBT.js";
import Popup from "../floating/Popup.js";
import QtyEditor from "./QtyEditor.js";
import DropDown from "../dropdown/DropDown.js";
import ListGroup from "react-bootstrap/ListGroup";

export default function CreateOrder({
  orderDetailsData,
  itemsData,
  products,
  categories,
  orderCompleted,
}) {
  const {
    data: previousProducts,
    loading: previousProductsLoading,
    error: previousProductsError,
  } = useFetch(`orders/PreviousProducts/${orderDetailsData.customerID}`);
  const [previousProductsList, setPreviousProductsList] =
    useState(previousProducts);

  const [orderData, setOrderData] = useState(orderDetailsData);
  const [trigger, setTrigger] = useState(false);
  const [orderItems, setOrderItems] = useState(itemsData);
  const [productToEdit, setProductToEdit] = useState(null);
  const [priceToEdit, setPriceToEdit] = useState(null);
  const [showPopup, setShowPopup] = useState({
    error: null,
    response: null,
    trigger: false,
  });
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const { postResponse, postLoading, postError, postData } = usePost("orders");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("orders/updateOrder");
  // error handling
  useEffect(() => {
    if (updateResponse || postResponse) {
      setShowPopup({
        ...showPopup,
        trigger: true,
        response: postResponse
          ? postResponse.message
          : updateResponse && updateResponse.message,
      });
      setOrderItems([]);
    } else if (postError || updateError) {
      setShowPopup({
        ...showPopup,
        trigger: true,
        error: postError || postError,
      });
    }
  }, [updateResponse, updateError, postResponse, postError]);
  useEffect(() => {
    if (previousProducts) {
      setPreviousProductsList(previousProducts);
    }
  }, [previousProducts]);

  const AddToOrder = (product) => {
    if (
      orderItems.filter((i) => product.productID === i.productID).length === 0
    ) {
      setOrderItems([...orderItems, product]);
      previousProductsList &&
        setPreviousProductsList(
          previousProductsList.filter((e) => e.productID !== product.productID)
        );
    } else {
      setShowPopup({
        ...showPopup,
        trigger: true,
        error:
          product.categoryName +
          " " +
          product.productName +
          " is already added",
      });
    }
  };

  const removeFromOrder = (product) => {
    if (product.orderDetailsID) {
      setItemsToDelete([...itemsToDelete, product.orderDetailsID]);
    }
    setOrderItems(orderItems.filter((i) => product.productID !== i.productID));
  };

  const showProductList = () => {
    setTrigger(true);
  };

  const afterPopup = (type) => {
    if (type) {
      orderCompleted();
    } else {
    }
    setShowPopup({
      error: null,
      response: null,
      trigger: false,
    });
  };

  const handleSubmit = () => {
    if (!orderData.deliveryDate || orderItems.length === 0) {
      if (!orderData.deliveryDate) {
        setShowPopup({
          ...showPopup,
          trigger: true,
          error: "Please select a delivery date",
        });
      } else {
        setShowPopup({
          ...showPopup,
          trigger: true,
          error: "No products added",
        });
      }
    } else {
      let orderDetails = [];
      let orderTotal = 0;
      orderItems.map((item) => {
        orderTotal = orderTotal + Number(item.price) * Number(item.quantity);
        orderDetails.push({
          orderDetailsID: item.orderDetailsID,
          productID: Number(item.productID),
          quantity: Number(item.quantity),
          price: Number(item.price),
        });
      });
      let order = {
        orderID: orderData.orderID,
        customerID: orderData.customerID,
        routeID: orderData.routeID,
        orderDate: sqlDate(),
        deliveryDate: sqlDate(orderData.deliveryDate),
        total: orderTotal,
        status: "New Order",
        note: orderData.note || null,
      };
      const data = { order, orderDetails, itemsToDelete };
      if (orderData.orderID) {
        console.log(data);
        updateData("", data);
      } else {
        postData(data);
      }
    }
  };

  //handle price and quantity change after addition
  const handleItemChange = (modifiedItem) => {
    const updatedOrderItems = orderItems.map((item) => {
      if (item.productID === modifiedItem.productID) {
        return modifiedItem;
      } else {
        return item;
      }
    });
    setOrderItems(updatedOrderItems);
    setProductToEdit(null);
    setPriceToEdit(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {productToEdit && (
        <QtyEditor
          productName={
            productToEdit.categoryName + " " + productToEdit.productName
          }
          value={productToEdit.quantity}
          setValue={(e) => setProductToEdit({ ...productToEdit, quantity: e })}
          floating={true}
          button={
            <SubmitBT
              BTname="Update Quantity"
              onClick={() => handleItemChange(productToEdit)}
            />
          }
        />
      )}
      <PriceEditor
        isActive={priceToEdit}
        item={priceToEdit}
        onSubmit={(e) => handleItemChange(e)}
      />

      <ProductPicker
        showList={trigger}
        setShowList={setTrigger}
        productData={products}
        categoryData={categories}
        selectedProduct={AddToOrder}
      />

      <div className="order-view">
        <OrderDetails
          orderDetails={orderData}
          setNote={(e) => setOrderData({ ...orderData, note: e })}
          setSelectedDate={(e) =>
            setOrderData({ ...orderData, deliveryDate: e })
          }
          orderDeliveryDate={
            orderData.deliveryDate && euDate(orderData.deliveryDate)
          }
        />
        <OrderList
          items={orderItems}
          itemToRemove={removeFromOrder}
          qtyToEdit={setProductToEdit}
          priceToEdit={setPriceToEdit}
          showProductList={showProductList}
          previousProducts={previousProductsList}
          productToAdd={AddToOrder}
        />
        <div className="another">
          <SubmitBT
            BTname={
              orderData.orderID != null && orderData.status !== "No Order"
                ? "Update Order"
                : "Create Order"
            }
            onClick={handleSubmit}
            loading={postLoading}
          />
        </div>
      </div>
      {showPopup.trigger && (
        <Popup
          error={showPopup.error}
          response={showPopup.response}
          setTrigger={afterPopup}
        />
      )}
    </div>
  );
}

const OrderDetails = ({
  orderDetails,
  orderDeliveryDate,
  setSelectedDate,
  setNote,
}) => {
  const dates = nextDeliveryDates(orderDetails?.days).map((day) => {
    return { label: euDate(day), value: sqlDate(day) };
  });
  dates.push({ label: "Custom Date", value: "Custom Date" });
  return (
    <div className="orderDetails" style={{ width: "100%" }}>
      <ListGroup horizontal>
        <ListGroup.Item style={{ width: "50%" }}>
          {orderDetails.customerName}
        </ListGroup.Item>
        <ListGroup.Item style={{ width: "50%" }}>
          {" "}
          {orderDetails.routeName}
        </ListGroup.Item>
      </ListGroup>

      <ListGroup horizontal>
        <ListGroup.Item style={{ width: "50%" }}>
          <input
            type="text"
            style={{
              backgroundColor: "inherit",
              border: 0,
              width: "100%",
              textAlign: "center",
              height: "100%",
            }}
            onChange={(e) => setNote(e.target.value)}
            placeholder={orderDetails.note || "Note"}
          />
        </ListGroup.Item>
        <ListGroup.Item style={{ width: "50%" }}>
          <DropDown
            options={dates}
            onSelect={(e) =>
              e.label === "Custom Date"
                ? alert("show Date")
                : setSelectedDate(e.value)
            }
            placeholder={orderDeliveryDate || "Select Delivery"}
          />
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

function PriceEditor({ isActive, item, onSubmit }) {
  const [product, setProduct] = useState(item);
  const priceRef = useRef(null);

  useEffect(() => {
    item && setProduct(item);
  }, [item]);

  return (
    isActive && (
      <div className="fixed-bg">
        <div
          className="fixed-inner"
          style={{
            height: "fit-content",
            textAlign: "center",
            width: "fit-content",
          }}
        >
          <h4>
            {" "}
            {product &&
              product.categoryName + " " + product.productName + " " + "Price"}
          </h4>
          <input
            className="text-center"
            type="number"
            /* autoFocus */
            ref={priceRef}
            onFocus={() => priceRef.current.select()}
            value={Number(product?.price)}
            onChange={(e) => setProduct({ ...product, price: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSubmit(product);
            }}
          />
          <SubmitBT BTname="Update Price" onClick={() => onSubmit(product)} />
        </div>
      </div>
    )
  );
}
