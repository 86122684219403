import "./Floating.css";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import { useEffect } from "react";

const Popup = ({ error, response, setTrigger, duration = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setTrigger(response ? true : false);
    }, duration);

    return () => clearTimeout(timer);
  }, [setTrigger, duration]);

  return (
    (error || response) && (
      <div
        className="fixed-bg"
        onClick={() => setTrigger(response ? true : false)}
      >
        <div className="popup-inner">
          {response && (
            <Check2Circle
              color="Green"
              fontSize={"35px"}
              style={{ marginLeft: "10px" }}
            />
          )}

          {error && (
            <XCircle
              color="#D02828"
              fontSize={"30px"}
              style={{ marginLeft: "10px" }}
            />
          )}

          <h6 style={{ margin: "0 10px" }}>
            {(response && response) || (error && error)}
          </h6>
        </div>
      </div>
    )
  );
};

export default Popup;
