import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../pages/login/Icon.png";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { userContext } from "../../fun/userContext";

function NavBar({ signout }) {
  const [expanded, setExpanded] = useState(false);
  const user = useContext(userContext);

  return (
    <>
      <Navbar
        key="lg"
        expand="lg"
        className="bg-body-tertiary mb-2"
        sticky="top"
        onToggle={() => setExpanded(!expanded)}
        expanded={expanded}
        collapseOnSelect
      >
        <Container fluid>
          <Link
            className="noLinkUnderline"
            to="/"
            onClick={() => window.history.replaceState({}, "")}
          >
            <Navbar.Brand>
              <img
                src={Logo}
                width="150px"
                className="d-inline-block align-top"
                alt="NourFoods logo"
              />
            </Navbar.Brand>
          </Link>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                NavBar
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">
                {(user.userType === "Admin" || user.userType === "Orders") && (
                  <>
                    <Link
                      className="nav-link"
                      to="/"
                      onClick={() => {
                        window.history.replaceState({}, "");
                        setExpanded(false);
                      }}
                    >
                      Home{" "}
                    </Link>
                    <NavDropdown
                      title="Orders"
                      id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                      <Link
                        className="dropdown-item"
                        to="/NewOrder"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        New Order
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/OrderHistory"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Order History
                      </Link>
                    </NavDropdown>
                    <NavDropdown
                      title="Manage"
                      id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                      <Link
                        className="dropdown-item"
                        to="/ManageProducts"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Manage Products{" "}
                      </Link>
                      <NavDropdown.Divider />{" "}
                      <Link
                        className="dropdown-item"
                        to="/ManageCustomers"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Manage Customers{" "}
                      </Link>
                      <NavDropdown.Divider />{" "}
                      <Link
                        className="dropdown-item"
                        to="/ManageRoutes"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Manage Routes{" "}
                      </Link>
                      <NavDropdown.Divider />{" "}
                      <Link
                        className="dropdown-item"
                        to="/ManageCategories"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Manage Categories{" "}
                      </Link>
                      {/*          <NavDropdown.Divider />{" "}
                      <Link
                        className="dropdown-item"
                        to="/ManageVans"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Manage Vans{" "}
                      </Link> */}
                      {user.userType === "Admin" && (
                        <>
                          {" "}
                          <NavDropdown.Divider />
                          <Link
                            className="dropdown-item"
                            to="/ManageUsers"
                            onClick={() => {
                              window.history.replaceState({}, "");
                              setExpanded(false);
                            }}
                          >
                            Manage Users{" "}
                          </Link>
                        </>
                      )}
                    </NavDropdown>
                  </>
                )}

                {user.userType !== "Driver" && (
                  <NavDropdown
                    title="Dispatch"
                    id={`offcanvasNavbarDropdown-expand-lg`}
                  >
                    <Link
                      className="dropdown-item"
                      to="/Dispatch"
                      onClick={() => {
                        window.history.replaceState({}, "");
                        setExpanded(false);
                      }}
                    >
                      Dispatch{" "}
                    </Link>
                    {(user.userType === "Admin" ||
                      user.userType === "Orders") && (
                      <Link
                        className="dropdown-item"
                        to="/Reports"
                        onClick={() => {
                          window.history.replaceState({}, "");
                          setExpanded(false);
                        }}
                      >
                        Dispatch Summry{" "}
                      </Link>
                    )}
                  </NavDropdown>
                )}
              </Nav>
              <Navbar.Text style={{ alignSelf: "end" }}>
                {user ? (
                  <>
                    Signed in as:{" "}
                    <Link
                      className="noLinkUnderline"
                      to="/Profile"
                      onClick={() => {
                        window.history.replaceState({}, "");
                        setExpanded(false);
                      }}
                    >
                      {user.username}
                    </Link>
                  </>
                ) : (
                  <Link
                    className="noLinkUnderline"
                    to="/Login"
                    onClick={() => {
                      window.history.replaceState({}, "");
                      setExpanded(false);
                    }}
                  >
                    Sign In
                  </Link>
                )}
              </Navbar.Text>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
