import { jsPDF } from "jspdf";
import Icon from "../../pages/login/Icon.png";
import React from "react";
import { useState, useEffect } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { ddmmyy, euDate } from "../../fun/fun";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: "5mm",
  },
  viewer: {
    width: "100%", // Set the width of the viewer to 100%
    height: "100vh", // Set the height of the viewer to full height of the viewport
  },

  table: {
    display: "table",
    justifyContent: "flex-start",
    width: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "1mm",
  },
});

const PageHeaders = ({ pageNo, NoOfPages }) => {
  const headerStyles = StyleSheet.create({
    image: {
      width: "50mm",
      margin: "0 auto",
    },
    title: {
      fontSize: 14,
      textAlign: "center",
    },
    docInfo: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  return (
    <View style={headerStyles.docInfo}>
      <Text style={headerStyles.title}>Dispatch Summary-Rev-1</Text>
      <Image style={headerStyles.image} src={Icon} />
      <Text style={headerStyles.title}>
        {"Page " + pageNo + " of " + NoOfPages}
      </Text>
    </View>
  );
};

const RouteDetails = ({ routeDetails }) => {
  const styles = StyleSheet.create({
    dispatchDetails: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
    },
    dispatchDetailsColHeader: {
      width: "15%",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      backgroundColor: "#f3f3f3",
    },
    dispatchDetailsCol: {
      width: "35%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#bfbfbf",
    },
    dispatchDetailsCellHeader: {
      fontSize: 12,
      fontWeight: "bold",
      margin: "0.4mm",
    },
    dispatchDetailsCell: {
      fontSize: 12,
      margin: "0.4mm",
      textAlign: "center",
    },
    dispatchDetailsRow: {
      margin: "auto",
      flexDirection: "row",
    },
  });
  return (
    <View style={styles.dispatchDetails}>
      <View style={styles.dispatchDetailsRow}>
        <View style={styles.dispatchDetailsColHeader}>
          <Text style={styles.dispatchDetailsCellHeader}>Route:</Text>
        </View>
        <View style={styles.dispatchDetailsCol}>
          <Text style={styles.dispatchDetailsCell}>
            {routeDetails.routeName}
          </Text>
        </View>
        <View style={styles.dispatchDetailsColHeader}>
          <Text style={styles.dispatchDetailsCellHeader}>Driver:</Text>
        </View>
        <View style={styles.dispatchDetailsCol}>
          <Text style={styles.dispatchDetailsCell}>{routeDetails.driver}</Text>
        </View>
      </View>
      <View style={styles.dispatchDetailsRow}>
        <View style={styles.dispatchDetailsColHeader}>
          <Text style={styles.dispatchDetailsCellHeader}>Date:</Text>
        </View>
        <View style={styles.dispatchDetailsCol}>
          <Text style={styles.dispatchDetailsCell}>
            {euDate(routeDetails.date)}
          </Text>
        </View>
        <View style={styles.dispatchDetailsColHeader}>
          <Text style={styles.dispatchDetailsCellHeader}>Vehicle Reg:</Text>
        </View>
        <View style={styles.dispatchDetailsCol}>
          <Text style={styles.dispatchDetailsCell}>{routeDetails.vanReg}</Text>
        </View>
      </View>
    </View>
  );
};
const TableHeaders = () => {
  const styles = StyleSheet.create({
    TableHeaderRow: {
      margin: "auto",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "50%",
    },

    otherCol: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#f3f3f3",
    },
    patchCol: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#f3f3f3",
    },
    ProductCol: {
      width: "50%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#f3f3f3",
    },

    tableCellHeader: {
      fontSize: 12,
      fontWeight: "bold",
      margin: "0.4mm",
      textAlign: "center",
    },
  });
  return (
    <View style={styles.TableHeaderRow}>
      <View style={styles.patchCol}>
        <Text style={styles.tableCellHeader}>Batch</Text>
      </View>
      <View style={styles.ProductCol}>
        <Text style={styles.tableCellHeader}>Product</Text>
      </View>
      <View style={styles.otherCol}>
        <Text style={styles.tableCellHeader}>Qty</Text>
      </View>
      <View style={styles.otherCol}>
        <Text style={styles.tableCellHeader}>Price</Text>
      </View>
    </View>
  );
};

const TableCustomerRow = ({ customerName }) => {
  const customerRowStyles = StyleSheet.create({
    tableRow: {
      margin: "auto",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "100%",
    },
    tableCell: {
      margin: "0.4mm",
      fontSize: 10,
      textAlign: "center",
    },
    customerName: {
      width: "100%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      justifyContent: "flex-start",
      backgroundColor: "#f2f2f2",
    },
  });
  return (
    <View style={customerRowStyles.tableRow}>
      <View style={customerRowStyles.customerName}>
        <Text style={customerRowStyles.tableCell}>{customerName}</Text>
      </View>
    </View>
  );
};

const TableNoteRow = ({ customerNote }) => {
  const customerRowStyles = StyleSheet.create({
    tableRow: {
      margin: "auto",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "100%",
    },
    tableCell: {
      margin: "0.4mm",
      fontSize: 10,
      textAlign: "center",
      fontWeight: "bold",
    },
    customerName: {
      width: "100%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      justifyContent: "flex-start",
    },
  });
  return (
    <View style={customerRowStyles.tableRow}>
      <View style={customerRowStyles.customerName}>
        <Text style={customerRowStyles.tableCell}>{customerNote}</Text>
      </View>
    </View>
  );
};

const TableRow = ({
  dispatchQTY = "",
  customerQTY = "",
  patch = "",
  productName = "",
  price = "",
  outOfstock = false,
  showPrice = false,
}) => {
  const orderDataStyles = StyleSheet.create({
    table: {
      display: "table",
      justifyContent: "flex-start",
      width: "100%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: "1mm",
    },

    tableRow: {
      margin: "auto",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "100%",
    },
    TableHeaderRow: {
      margin: "auto",
      justifyContent: "flex-start",
      flexDirection: "row",
      width: "50%",
    },

    tableColHeader: {
      width: "25%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: "#f3f3f3",
    },
    otherCol: {
      width: "15%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      justifyContent: "flex-start",
    },
    patchCol: {
      width: "20%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      justifyContent: "flex-start",
    },
    ProductCol: {
      width: "50%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      justifyContent: "flex-start",
    },
    tableCellHeader: {
      fontSize: 12,
      fontWeight: "bold",
      margin: "0.4mm",
      textAlign: "center",
    },
    tableCell: {
      margin: "0.4mm",
      fontSize: 10,
      textAlign: "center",
    },
    productCell: {
      margin: "0.4mm",
      fontSize: 10,
      textAlign: "left",
    },
    outOfstock: {
      textDecoration: "line-through",
    },
  });
  return (
    <>
      <View style={orderDataStyles.tableRow}>
        <View style={orderDataStyles.patchCol}>
          <Text style={orderDataStyles.tableCell}>{patch}</Text>
        </View>
        <View style={orderDataStyles.ProductCol}>
          <Text
            style={[
              orderDataStyles.productCell,
              outOfstock && orderDataStyles.outOfstock,
            ]}
          >
            {productName + " - " + customerQTY}
          </Text>
        </View>
        <View style={orderDataStyles.otherCol}>
          <Text style={orderDataStyles.tableCell}>{dispatchQTY}</Text>
        </View>
        <View style={orderDataStyles.otherCol}>
          <Text style={orderDataStyles.tableCell}>
            {showPrice ? price : "-"}
          </Text>
        </View>
      </View>
    </>
  );
};

// Create Document Component
const MyDocument = ({ routeDetails, orders }) => {
  let leftSide = [];
  let rightSide = [];
  const ordersPage = [];
  let leftCount = 0;
  let rightCount = 0;
  const pageLimit = 50;

  const countChar = (value = "", maxChar) => {
    return Math.ceil(value.length / maxChar);
  };

  const orderLineCounter = (order) => {
    let count = 0;
    //count customer line
    count = count + countChar(order.customerName, 52);

    //count item name line
    order.orderDetails.map((item) => {
      const orderItem =
        item.productName +
        item.categoryName +
        "1234" +
        parseFloat(item.quantity).toString();
      count = count + countChar(orderItem, 28);
      if (item.batch) count = count + countChar(item.batch, 7) - 1;
    });
    //count note line
    if (order.note) {
      count = count + countChar(order.note, 52);
    }
    return count;
  };

  if (orders) {
    for (let index = 0; index < orders.length; index++) {
      const orderTotalLineCount = orderLineCounter(orders[index]);
      let addToPage = false;
      let orderAdded = false;
      // add the order to previous pages if posible
      if (ordersPage.length > 0) {
        for (let x = 0; x < ordersPage.length; x++) {
          if (
            ordersPage[x].leftCount + orderTotalLineCount < pageLimit &&
            !orderAdded
          ) {
            ordersPage[x].leftCount =
              ordersPage[x].leftCount + orderTotalLineCount;
            ordersPage[x].leftSide.push(orders[index]);
            orderAdded = true;
            if (ordersPage.length === x + 1 && orders.length === index + 1)
              addToPage = true;
          } else if (
            ordersPage[x].rightCount + orderTotalLineCount < pageLimit &&
            !orderAdded
          ) {
            ordersPage[x].rightCount =
              ordersPage[x].rightCount + orderTotalLineCount;
            ordersPage[x].rightSide.push(orders[index]);
            orderAdded = true;
            if (ordersPage.length === x + 1 && orders.length === index + 1)
              addToPage = true;
          } else {
            orderAdded = false;
          }
        }
      }

      if (!orderAdded) {
        if (leftCount + orderTotalLineCount < pageLimit) {
          leftCount = leftCount + orderTotalLineCount;
          leftSide.push(orders[index]);
          if (orders.length === index + 1) addToPage = true;
        } else if (rightCount + orderTotalLineCount < pageLimit) {
          rightCount = rightCount + orderTotalLineCount;
          rightSide.push(orders[index]);
          if (orders.length === index + 1) addToPage = true;
        } else {
          ordersPage.push({ leftSide, rightSide, leftCount, rightCount });
          leftSide = [];
          rightSide = [];
          rightCount = 0;
          leftSide.push(orders[index]);
          leftCount = orderTotalLineCount;
        }
      }

      if (addToPage) {
        ordersPage.push({ leftSide, rightSide, leftCount, rightCount });
        leftSide = [];
        rightSide = [];
        leftCount = 0;
        rightCount = 0;
      }
    }
  }

  return (
    ordersPage && (
      <Document>
        {ordersPage.map((page, index) => (
          <Page style={styles.page}>
            <PageHeaders pageNo={index + 1} NoOfPages={ordersPage.length} />
            <RouteDetails routeDetails={routeDetails} />
            <View style={styles.table}>
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                <TableHeaders />
                <TableHeaders />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <View style={{ width: "50%" }}>
                  {page.leftSide.map((order) =>
                    order.customerName !== "" ? (
                      <>
                        <TableCustomerRow customerName={order.customerName} />
                        {order.orderDetails.map((item) => (
                          <TableRow
                            showPrice={routeDetails.showPrice}
                            patch={item.batch}
                            dispatchQTY={parseFloat(item.dispatchQTY)}
                            customerQTY={parseFloat(item.quantity)}
                            productName={
                              item.categoryName + " " + item.productName
                            }
                            price={parseFloat(item.newPrice)}
                            outOfstock={item.outOfStock}
                          />
                        ))}

                        {order.note && (
                          <TableNoteRow customerNote={order.note} />
                        )}
                      </>
                    ) : (
                      <TableRow
                        patch={""}
                        dispatchQTY={""}
                        customerQTY={""}
                        productName={""}
                        price={""}
                        outOfstock={false}
                      />
                    )
                  )}
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  {page.rightSide.map((order) =>
                    order.customerName !== "" ? (
                      <>
                        <TableCustomerRow customerName={order.customerName} />
                        {order.orderDetails.map((item) => (
                          <TableRow
                            showPrice={routeDetails.showPrice}
                            patch={item.batch}
                            dispatchQTY={parseFloat(item.dispatchQTY)}
                            customerQTY={parseFloat(item.quantity)}
                            productName={
                              item.categoryName + " " + item.productName
                            }
                            price={parseFloat(item.newPrice)}
                            outOfstock={item.outOfStock}
                          />
                        ))}

                        {order.note && (
                          <TableNoteRow customerNote={order.note} />
                        )}
                      </>
                    ) : (
                      <TableRow
                        patch={""}
                        dispatchQTY={""}
                        customerQTY={""}
                        productName={""}
                        price={""}
                        outOfstock={false}
                      />
                    )
                  )}
                </View>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  );
};

const DispatchSheet = ({ routeDetails, orders }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    }}
  >
    <PDFDownloadLink
      document={<MyDocument orders={orders} routeDetails={routeDetails} />}
      fileName={
        routeDetails.routeName + "-" + ddmmyy(routeDetails.date) + ".pdf"
      }
    >
      {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
    </PDFDownloadLink>
    <PDFViewer style={styles.viewer}>
      <MyDocument orders={orders} routeDetails={routeDetails} />
    </PDFViewer>
  </div>
);

export default DispatchSheet;
