import Loader from "../loader/Loader";

export default function SubmitBT({
  BTname = "Submit",
  loading = false,
  onClick,
  hidden,
  ...rest
}) {
  return (
    <button
      className="submitBT"
      onClick={onClick}
      type="submit"
      hidden={hidden}
      {...rest}
    >
      {loading ? <Loader color={"white"} /> : BTname}
    </button>
  );
}
