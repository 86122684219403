import ListGroup from "react-bootstrap/ListGroup";
import { useState, useEffect } from "react";
import { CheckAll, X } from "react-bootstrap-icons";
import { useUpdate, useFetch } from "../../db/dbHooks";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import SubmitBT from "../../components/buttons/SubmitBT";
import Popup from "../../components/floating/Popup";
import { getFromLocalDB, storeInLocalDB } from "../../fun/fun";

const DispatchProductMiddle = ({ selectedProduct, update }) => {
  const { data, loading, error, refetch } = useFetch(
    `orderDetails/orderDetailsByProduct/${selectedProduct.productID}`
  );

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [popup, setPopup] = useState({ trigger: false, message: "" });
  const [batchNo, setBatchNo] = useState("");
  const [customers, setCustomers] = useState(null);
  // const [qtyToEdit, setQtyToEdit] = useState(null);

  const [batchNoHint, setBatchNoHint] = useState(
    getFromLocalDB("productID:" + selectedProduct.productID)
  );
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("dispatch/patch");
  useEffect(() => {
    data && setCustomers(data);
  }, [data]);
  useEffect(() => {
    let sum = 0;
    selectedCustomers.map((item) => {
      sum += item.dispatchQTY
        ? Number(item.dispatchQTY)
        : Number(item.quantity);
    });
    setSelectedQuantity(sum);
  }, [selectedCustomers]);

  useEffect(() => {
    updateResponse && refetch();
    setSelectedCustomers([]);
    setBatchNoHint(getFromLocalDB("productID:" + selectedProduct.productID));
    setBatchNo("");
  }, [updateResponse, selectedProduct]);

  const handleSubmit = () => {
    if (selectedQuantity !== 0 && batchNo !== "") {
      storeInLocalDB("productID:" + selectedProduct.productID, batchNo);
      setBatchNoHint(batchNo);

      const dataToPost = selectedCustomers.map((item) => {
        return { orderDetailsID: item.orderDetailsID, batch: batchNo };
      });

      updateData("", { updatedOrderItems: dataToPost });
    } else {
      batchNo === "" &&
        setPopup({ trigger: true, message: "Enter Batch Number" });
      selectedQuantity === 0 &&
        setPopup({ trigger: true, message: "Nothing selected" });
    }
  };

  /*   const handleQtyUpdate = (product) => {
      if (product) {
        setCustomers(
          customers.map((item) => {
            if (item.customerID !== product.customerID) {
              return item;
            } else {
              return product;
            }
          })
        );
        setQtyToEdit(null);
      } else {
        setQtyToEdit(null);
      }
    }; */

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "650px",
        backgroundColor: "#ececec",
        padding: "5px",
        borderRadius: 15,
      }}
    >
      <h3>
        {selectedProduct.categoryName + " " + selectedProduct.productName}
      </h3>
      <h6>Ordered By</h6>
      <div
        style={{
          width: "100%",
          maxHeight: "40vh",
          overflowY: "auto",
        }}
      >
        <ListGroup
          horizontal
          className="list-header"
          style={{ textAlign: "center" }}
        >
          <ListGroup.Item className="w-50" style={{ textAlign: "left" }}>
            Customer
          </ListGroup.Item>
          <ListGroup.Item className="w-25">Route</ListGroup.Item>
          <ListGroup.Item className="w-25">Batch</ListGroup.Item>
          <ListGroup.Item className="w-25">Quantity</ListGroup.Item>
        </ListGroup>
        {customers &&
          customers.map((item, index) => (
            <ListGroup
              horizontal
              style={{ textAlign: "center" }}
              key={index}
              onClick={() =>
                setSelectedCustomers(
                  selectedCustomers.find(
                    (customer) => customer.customerID === item.customerID
                  )
                    ? selectedCustomers.filter(
                        (customer) => customer.customerID !== item.customerID
                      )
                    : [...selectedCustomers, item]
                )
              }
            >
              <ListGroup.Item
                className="w-50"
                style={{ textAlign: "left" }}
                active={selectedCustomers.find(
                  (customer) => customer.customerID === item.customerID
                )}
              >
                {item.customerName}
              </ListGroup.Item>
              <ListGroup.Item
                className="w-25"
                style={{ alignContent: "center" }}
                active={selectedCustomers.find(
                  (customer) => customer.customerID === item.customerID
                )}
              >
                {item.routeName}
              </ListGroup.Item>

              <ListGroup.Item
                style={{ alignContent: "center" }}
                className="w-25"
                active={selectedCustomers.find(
                  (customer) => customer.customerID === item.customerID
                )}
              >
                {item.batch}
              </ListGroup.Item>

              <ListGroup.Item
                className="w-25"
                style={{
                  backgroundColor: item.dispatchQTY && "#FDCA40",
                  alignContent: "center",
                }}
                active={selectedCustomers.find(
                  (customer) => customer.customerID === item.customerID
                )}
                /*  onClick={() =>
                    setQtyToEdit({
                      ...item,
                      productName: selectedProduct.productName,
                      categoryName: selectedProduct.categoryName,
                    })
                  } */
              >
                {(item.dispatchQTY
                  ? Number(item.dispatchQTY)
                  : Number(item.quantity)) +
                  "" +
                  selectedProduct.unit}
              </ListGroup.Item>
            </ListGroup>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          columnGap: "10px",
          padding: "5px",
          justifyContent: "end",
        }}
      >
        <X size={40} onClick={() => setSelectedCustomers([])} />

        <CheckAll
          size={40}
          color="black"
          onClick={() => setSelectedCustomers(customers)}
        />
      </div>
      {batchNoHint && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            columnGap: 20,
          }}
        >
          <p style={{ margin: 0, fontWeight: 600 }}>Last Batch Used:</p>
          <button
            style={{
              borderRadius: 20,
              borderColor: "#dee2e6",
              borderStyle: "solid",
              backgroundColor: "white",
            }}
            onClick={() => setBatchNo(batchNoHint)}
          >
            {batchNoHint}
          </button>
        </div>
      )}

      <InputGroup className="mb-3 mt-3">
        <InputGroup.Text id="inputGroup-sizing-default">
          Batch No for {selectedQuantity + selectedProduct.unit}:
        </InputGroup.Text>
        <Form.Control
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          onChange={(e) => setBatchNo(e.target.value)}
          value={batchNo}
        />
      </InputGroup>

      <SubmitBT
        BTname="Update Selected Items"
        onClick={handleSubmit}
        loading={updateLoading}
      />
      {/*    {qtyToEdit && (
          <FloatingQtyEditor item={qtyToEdit} onSubmit={handleQtyUpdate} />
        )} */}
      {popup.trigger && (
        <Popup
          error={popup.message}
          setTrigger={() =>
            setPopup({
              message: null,
              trigger: false,
            })
          }
        />
      )}
    </div>
  );
};

export default DispatchProductMiddle;
