import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { usePost, useUpdate } from "../../db/dbHooks";
import SubmitBT from "../buttons/SubmitBT";
import Popup from "../floating/Popup";

export default function Category({ categoryToEdit = null, reFetch }) {
  const [validated, setValidated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [category, setCategory] = useState(
    categoryToEdit
      ? categoryToEdit
      : {
          categoryName: "",
        }
  );
  const { postResponse, postLoading, postError, postData } =
    usePost("categories");
  const { updateResponse, updateLoading, updateError, updateData } =
    useUpdate("categories");

  useEffect(() => {
    if (postError || updateError) {
      setShowPopup(true);
    } else if (postResponse || updateResponse) {
      setShowPopup(true);
      setValidated(false);
      resetState();
    } else if (!categoryToEdit) {
      resetState();
    }
  }, [categoryToEdit, postResponse, updateResponse, postError, updateError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (category.categoryName.length >= 3) {
        if (categoryToEdit) {
          updateData(category.categoryID, category);
        } else {
          postData(category);
        }
      }
    }
    setValidated(true);
  };

  const handleShowPopup = () => {
    setShowPopup(false);
    reFetch();
  };

  const resetState = () => {
    setCategory({
      categoryName: "",
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="form"
    >
      <Form.Group as={Col}>
        <Form.Label>Category Name</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="Enter category name"
          id="categoryName"
          autoComplete="off"
          value={category.categoryName}
          onChange={(e) =>
            setCategory({ ...category, categoryName: e.target.value })
          }
        />
      </Form.Group>
      <SubmitBT
        BTname={categoryToEdit ? "Update Category" : "Add Category"}
        loading={postLoading || updateLoading}
      />
      {showPopup && (
        <Popup
          error={postError || updateError}
          response={postResponse?.message || updateResponse?.message}
          setTrigger={handleShowPopup}
        />
      )}
    </Form>
  );
}
